import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { RestLink } from 'apollo-link-rest';
import { onError } from 'apollo-link-error';

import { throttle } from '../helpers/throttle_debounce';
import { promise_refresh_user } from '../requests/user';
import { BRUINS_API_URL, LOGOUT_ROUTE } from '../constants';

import { initTracker } from 'helpers/tracker';
import trackerGraphQL from '@openreplay/tracker-graphql';
import trackerAssist from '@openreplay/tracker-assist';

const { trackGraphQL } = initTracker({
  plugins: {
    trackGraphQL: trackerGraphQL,
    trackAssist: trackerAssist,
  },
});

const mergeCombine = ({ keyArgs = [] } = {}) => ({
  keyArgs,
  merge(existing, incoming) {
    if (!existing) return incoming;

    return [...existing, ...incoming];
  },
});

const mergePaginated = ({ extraArgs = [] } = {}) => ({
  // Paginated queries need custom merge
  // Cache requests with different filters separately
  keyArgs: ['filters', ...extraArgs],
  // Concatenate the incoming data field with existing
  // assumes pages are in order and without jumps
  merge(existing = { pageInfo: null, data: [] }, incoming) {
    return {
      pageInfo: {
        ...incoming.pageInfo,
        hasPreviousPage: !!existing.pageInfo
          ? existing.pageInfo.hasPreviousPage
          : incoming.pageInfo.hasPreviousPage,
        startIndex: !!existing.pageInfo ? existing.pageInfo.startIndex : 0,
      },
      data: [...existing.data, ...incoming.data],
    };
  },
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        clientReportState: {
          read(_, { args }) {
            if (!args) return reportStateVar();
            if (args.id) {
              return reportStateVar()[args.id];
            }
            return reportStateVar();
          },
        },
        allReports: mergePaginated({ extraArgs: ['scoutingObjectiveSlug'] }),
        reportGames: mergePaginated(),
        // paginatedNotifications: mergePaginated(),
      },
    },
    Roles: { keyFields: ['id'] },
    BDDDepthChart: {
      fields: {
        depthChartPlayers: { merge: false }, // prefer incoming data over existing
      },
    },
  },
});

export const reportStateVar = cache.makeVar({});

const refreshAuth = () => {
  promise_refresh_user()
    .then((res) => {
      console.log(`[Refreshing Access Token]`);
      localStorage.setItem('access_csrf', res.data.access_csrf);
      window.location.reload();
    })
    .catch((err) => {
      console.error(`[Auth Token Refresh Error]: ${err.response}`);
      window.location.href = LOGOUT_ROUTE;
    });
};
const throttledRefreshAuth = throttle(refreshAuth, 10000); // throttled to 10 seconds

const errorLink = onError(({ graphQLErrors, networkError, operation, ...rest }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      console.error(`[GraphQL Error]: Message: ${message}, Path: ${path}`);
    });
  }
  if (networkError) {
    if (networkError.statusCode === 401) {
      // Use refresh token
      throttledRefreshAuth();
    } else if (networkError.statusCode === 422) {
      console.error('Unrecognized Token, forcing logout');
      window.location.href = LOGOUT_ROUTE;
    }
    try {
      networkError.response.json().then(console.error);
    } catch {}
    console.error(`[Network Error]: ${operation.operationName}: ${networkError.message}`);
  }
});

const authLink = setContext((_, { headers }) => {
  const csrf = localStorage.getItem('access_csrf');
  return {
    headers: {
      ...headers,
      'X-CSRF-TOKEN': csrf ? csrf : '',
    },
  };
});

const restLink = new RestLink({
  uri: BRUINS_API_URL,
  headers: null,
  credentials: 'include',
});

const httpLink = new HttpLink({
  uri: `${BRUINS_API_URL}/graphql`,
  credentials: 'include',
});

const trackerApolloLink =
  trackGraphQL &&
  new ApolloLink((operation, forward) => {
    const operationDefinition = operation.query.definitions[0];
    let { operationName, variables } = operation;
    const { kind, operation: op } = operationDefinition;
    const opKind = kind === 'OperationDefinition' ? op : 'unknown?';

    let results = forward(operation).map((result) => {
      return trackGraphQL(opKind, operationName, variables, result);
    });
    if (results.length === 0) {
      //if there are no results, then we've not tracked anything so far...
      trackGraphQL(opKind, operationName, variables, {});
    }
    return results;
  });

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${BRUINS_API_URL}/graphql?${operation.operationName}`,
  }));
  return forward ? forward(operation) : null;
});

const client = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([
    errorLink,
    authLink,
    restLink,
    namedLink,
    ...(trackerApolloLink ? [trackerApolloLink] : []),
    httpLink,
  ]),
});

export default client;
