import React, { useEffect, useState } from 'react';
import { theme } from 'constants';
import { ChevronDown, ChevronUp, Clipboard, PlayFill } from 'react-bootstrap-icons';
import styled from 'styled-components';
import { bruinsGold } from 'helpers/plotting';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { eventNametoValueKey, getEventTypeOptions, getOutcomeOptions } from './helpers';
import { useQuery } from '@apollo/client';
import { GET_SL_EVENT_NAMES } from 'apollo/queries/events.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import Icon from 'components/bdd/Icon';
import { useOnScreenRef } from 'components/bdd/hooks';
import { EventShiftContext } from '../components';
import { LinkButton } from 'components/bdd/Button';
import { EventHover } from './EventHelpers';
import { roundToX } from 'helpers/data';

const EventStyles = styled.div({
  ...theme.font.base,
  display: 'flex',
  width: '100%',
  minHeight: 70,
  padding: theme.spacing[1],
  cursor: 'pointer',
  borderBottom: (props) =>
    !props.showBorder
      ? 'none'
      : props.selected
      ? `1px solid ${bruinsGold}`
      : '1px solid #333',
  boxShadow: (props) => (props.selected ? `inset 1px 1px 5px ${bruinsGold}` : null),
});

export default function Event({ small, ...props }) {
  if (small) return <EventSmall {...props} />;
  return <EventDefault {...props} />;
}

export const useEventLabel = ({ event, name, description, number }) => {
  const { data } = usePlaceholder(useQuery(GET_SL_EVENT_NAMES, { skip: !event }));
  const options = !!data
    ? data.slEventNames.map((e) => ({ value: e.name, label: e.label }))
    : [];

  if (!event) return null;

  return options ? (
    <Container>
      <Row columnGap={1} flexWrap alignItems="baseline">
        {!!name && !!number && (
          <Typography as="span" variant="subtitle1">
            <b>{number}.</b>
          </Typography>
        )}
        {name && (
          <Typography as="span" variant="subtitle1">
            <b>{name}</b>
          </Typography>
        )}
        {description && (
          <Typography as="span" variant="body2">
            {description}
          </Typography>
        )}
      </Row>
      <Row flexWrap columnGap={1} rowGap={1}>
        {!name && !!number && <b>{number}.</b>}
        <b>{options?.find((o) => o.value == event.eventName)?.label}</b>
        {' | '}
        {getEventActor(event, true)}
        {' | '}
        {event.eventType && (
          <>
            {
              getEventTypeOptions(event.eventName).find(
                (et) => et.value == event.eventType
              )?.label
            }
            {' | '}
          </>
        )}
        {getOutcomeOptions(event.eventName).find((o) => o.value == event.outcome)?.label}
        <EventHover event={event}>
          <Icon icon={<Clipboard />} size={12} marginLeft={2} />
        </EventHover>
      </Row>
    </Container>
  ) : (
    <Typography variant="body1">Loading Event...</Typography>
  );
};

const getEventActor = (event, useNumber = false) =>
  !!event.player
    ? useNumber
      ? `${event.team.shorthand} ${event.player.firstname} ${event.player.lastname} #${event.player.jerseynumber}`
      : `${event.player.firstname} ${event.player.lastname}`
    : event.team.shorthand;

const getEventValueLabel = (event) => {
  const value = !!event.value2 ? event.value2 : event.value;

  if (!(event.eventName in eventNametoValueKey)) {
    return null;
  }

  return (
    <Typography variant="stat">
      <b>{eventNametoValueKey[event.eventName]}: </b>
      {roundToX(value, 2)}
    </Typography>
  );
};

const EventSmall = ({ event, selected, onClick }) => {
  return (
    <EventStyles selected={selected} onClick={onClick}>
      <SmallEventWrapper>
        {event.formattedTime?.split(' ')[1]} {getEventActor(event, true)}{' '}
        {event.eventName.replace(/_/g, ' ').toUpperCase()} {event.eventType}
        {!event.outcome
          ? ''
          : event.outcome === 'successful'
          ? '+'
          : event.outcome === 'failed'
          ? '-'
          : event.outcome[0].toUpperCase()}
      </SmallEventWrapper>
    </EventStyles>
  );
};

const EventDefault = ({
  event,
  number,
  name,
  description,
  selected,
  onClick,
  showBorder = true,
}) => {
  const [showShiftContext, setShowShiftContext] = useState(false);
  const { ref, hasBeenVisible } = useOnScreenRef({ defaultValue: true });
  const eventLabel = useEventLabel({ event, name, description, number });

  return (
    <EventStyles selected={selected} ref={ref} showBorder={showBorder}>
      {hasBeenVisible && (
        <Container padding={1} flexGrow>
          <Typography variant="body2" onClick={onClick}>
            <Row flexWrap columnGap={1} rowGap={1}>
              <Typography variant="body2">{eventLabel}</Typography>
            </Row>
            <Typography variant="caption">
              <Row columnGap={1} flexWrap>
                {!!event.eventFlags &&
                  event.eventFlags != '{}' &&
                  event.eventFlags
                    .split(',')
                    .map((ef) => <div key={`${event.id}-${ef}`}>{ef}</div>)}
                {!!event.precedingFlags &&
                  event.precedingFlags != '{}' &&
                  event.precedingFlags
                    .split(',')
                    .map((ef) => <div key={`${event.id}-${ef}`}>{ef}</div>)}
                {!!event.successiveFlags &&
                  event.successiveFlags != '{}' &&
                  event.successiveFlags
                    .split(',')
                    .map((ef) => <div key={`${event.id}-${ef}`}>{ef}</div>)}
              </Row>
            </Typography>
            <Typography variant="stat">
              <Row justifyContent="space-between">
                <Row columnGap={2}>
                  <b>
                    {event.slGame.gameDate} vs {event.oppTeam.shorthand}
                  </b>{' '}
                  {event.formattedTime} {getEventValueLabel(event)}
                </Row>
                <Container>
                  <LinkButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowShiftContext(!showShiftContext);
                    }}
                  >
                    <Row columnGap={1}>
                      <Icon icon={!showShiftContext ? <ChevronDown /> : <ChevronUp />} />
                      <Typography variant="stat">
                        {showShiftContext ? 'Hide' : 'Show'} shift TOI
                      </Typography>
                    </Row>
                  </LinkButton>
                </Container>
              </Row>
              {showShiftContext && <EventShiftContext event={event} />}
            </Typography>
          </Typography>
        </Container>
      )}
    </EventStyles>
  );
};
