import { Container } from "components/bdd/Layout"
import { useMutation } from "@apollo/client"
import { toastBddApiError, toastInfo } from "components/bdd/bddtoasts";
import { UPDATE_BDD_TRADE } from "apollo/queries/bddtransactions.queries";
import { tradeAssetsToPicks, tradeAssetsToPlayers } from "./helpers";
import { useBDDTradeForm } from "./useBDDTradeForm";


export const EditBDDTrade = ({ bddTrade, onCompleted }) => {

  const [updateBDDTrade, { loading }] = useMutation(UPDATE_BDD_TRADE, {
    onError: toastBddApiError
  });

  const handleSubmit = (teams, assets) => {
    updateBDDTrade({
      onCompleted: () => {
        toastInfo('Trade updated');
        if (!!onCompleted) onCompleted();
      },
      variables: {
        tradeId: bddTrade.id,
        input: {
          tradedPlayers: tradeAssetsToPlayers(assets),
          tradedPicks: tradeAssetsToPicks(assets),
        }
      }
    })
  }

  const tradeFormProps = {
    defaultTeams: bddTrade.teamSummaries.map(t => t.teamSlug),
    defaultAssets: bddTrade.tradedPlayers.map(p => ({
      assetType: "PLAYER",
      assetId: p.playerSlug,
      fromTeamSlug: p.fromTeamSlug,
      toTeamSlug: p.toTeamSlug,
      bddPlayer: p.bddPlayer,
      retainedSalary: p.retainedSalary,
      retainedSalaryPct: p.retainedSalaryPct
    })).concat(bddTrade.tradedDraftPicks.map(p => ({
      assetType: "DRAFT_PICK",
      assetId: p.pickId,
      fromTeamSlug: p.fromTeamSlug,
      toTeamSlug: p.toTeamSlug,
      cfDraftPick: p.cfDraftPick,
    })))
  }

  const { form } = useBDDTradeForm({
    showSubmit: true,
    handleSubmit,
    submitting: loading,
    ...tradeFormProps
  });
  
  return <Container>
    {form}
  </Container>
}