import { formatDateGQL } from 'helpers/helpers';
import { getSeasonPlusMinusYears, season8to4 } from 'helpers/hockeyutils';

export const buildIdToQueryFilters = (filters) => {
  const season = filters['season']?.value;
  const browse = filters['browse']?.value;

  return {
    is_expiry_status_arbitration_eligible: () => {
      return [
        {
          col: 'expiry_status_details',
          cond: '~@>',
          val: 'Arbitration Eligible',
        },
      ];
    },
    min_signing_date: (filter) => {
      return [
        {
          col: 'signed_date',
          cond: '>=',
          val: formatDateGQL(filter.value),
        },
      ];
    },
    max_signing_date: (filter) => {
      return [
        {
          col: 'signed_date',
          cond: '<=',
          val: formatDateGQL(filter.value),
        },
      ];
    },
    position: (filter) => {
      if (filter.value == 'All Skaters') {
        return [
          {
            col: 'position',
            cond: '!=',
            val: 'G',
          },
        ];
      }

      if (filter.value == 'Forwards') {
        return [
          {
            col: 'position',
            cond: 'includes_substr',
            val: ['LW', 'RW', 'C'],
          },
        ];
      }

      if (filter.value == 'Defensemen') {
        return [
          {
            col: 'position',
            cond: 'includes_substr',
            val: ['LD', 'RD'],
          },
        ];
      }

      if (filter.value == 'Wingers') {
        return [
          {
            col: 'position',
            cond: 'includes_substr',
            val: ['LW', 'RW'],
          },
        ];
      }

      return [
        {
          col: 'position',
          cond: '~',
          val: filter.value,
        },
      ];
    },
    season: (filter) => {
      if (['free-agents', 'rfas', 'ufas', 'all'].includes(browse)) {
        return [];
      }
      return {
        col: 'season',
        cond: '=',
        val: filter.value,
      };
    },
    browse: (filter) => {
      const freeAgentFilters = [
        {
          col: 'end_season',
          cond: '=',
          val: getSeasonPlusMinusYears(season, -1),
        },
        {
          col: 'free_agent_year',
          cond: '=',
          val: season.toString().slice(0, 4),
        },
      ];

      switch (filter.value) {
        case 'active-players':
          return [
            {
              col: 'is_active_or_future',
              cond: '=',
              val: true,
            },
            {
              col: 'is_bought_out',
              cond: '=',
              val: false,
            },
          ];
        case 'free-agents':
          return freeAgentFilters;
        case 'rfas':
          return [
            ...freeAgentFilters,
            {
              col: 'expiry_status',
              cond: '=',
              val: 'RFA',
            },
          ];
        case 'ufas':
          return [
            ...freeAgentFilters,
            {
              col: 'expiry_status',
              cond: '=',
              val: 'UFA',
            },
          ];
        case 'elcs':
          return [
            {
              col: 'signing_status',
              cond: '=',
              val: 'ELC',
            },
          ];
        case 'all':
        default:
          return [];
      }
    },
  };
};

const sortMapping = {
  contract: 'aav_sum',
  player: 'last_name',
  height: 'height_inches',
};

export const mapSort = (sort) => {
  // given a sort [{ id, desc }] map columns as necessary
  // some columns (contract) map to others (final_cap_hit) when sorting
  return sort.map((s) => {
    if (s.id in sortMapping) {
      return { ...s, id: sortMapping[s.id] };
    } else {
      return s;
    }
  });
};
