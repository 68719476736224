import { HoverInteractive } from 'components/bdd/bddcomponents';
import { IconButton } from 'components/bdd/Button';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { WindowContext } from 'components/bdd/context';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { theme } from 'constants/theme';
import { formatGameTime, formatSeconds } from 'helpers/helpers';
import { bruinsGold, getColorWithOpacity } from 'helpers/plotting';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  PauseFill,
  Pin,
  PlayFill,
  SkipBackwardFill,
  SkipForwardFill,
  Speedometer2,
  VolumeMuteFill,
  VolumeUpFill,
} from 'react-bootstrap-icons';
import styled from 'styled-components';
import { VideoClipContext, VideoPlayerContext } from '..';
import { VideoClipSlider } from './VideoClipSlider';

const InputTick = styled.div({
  position: 'absolute',
  height: '100%',
  width: 4,
  left: (props) => `${props.eventTimePct}%`,
  top: 0,
  backgroundColor: bruinsGold,
  cursor: 'pointer',
});

export const VideoClipControls = ({ showTick = true }) => {
  const {
    playerState,
    duration,
    startTime,
    endTime,
    togglePlay,
    toggleMute,
    handleVideoProgress,
    handleVideoSpeed,
    handleVideoTrim,
    seekToSeconds,
    setFrontPad,
    setBackPad,
  } = useContext(VideoPlayerContext);

  const { height } = useContext(WindowContext);
  const [opacity, setOpacity] = useState(0.7);
  const absoluteLayout =
    height <= theme.breakpoints.xs
      ? {
          position: 'absolute',
          width: '100%',
          bottom: 0,
          opacity: opacity,
          transition: 'all 0.3s ease-in-out',
        }
      : {};

  const [timerId, setTimerId] = useState();
  const [controlsPinned, setControlsPinned] = useState(false);
  const handleOpacityChange = () => {
    setOpacity(0.7);

    if (timerId) {
      clearTimeout(timerId);
    }

    if (!controlsPinned) {
      const newTimerId = setTimeout(() => {
        setOpacity(0);
        setTimerId(null);
      }, 3000);

      setTimerId(newTimerId);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOpacityChange);
    return () => {
      document.removeEventListener('click', handleOpacityChange);
    };
  }, [timerId, controlsPinned]);

  const { currentClip, setNextClip, setPreviousClip, clipTrimControlsToggled } =
    useContext(VideoClipContext);

  const { buttonGroup, selectedOption } = useButtonGroup({
    initialSelectedValue: 1,
    options: [
      {
        value: 0.5,
        label: '0.5x',
      },
      {
        value: 0.75,
        label: '0.75x',
      },
      {
        value: 1,
        label: '1x',
      },
      {
        value: 1.25,
        label: '1.25x',
      },
      {
        value: 1.5,
        label: '1.5x',
      },
    ],
  });

  useEffect(() => {
    handleVideoSpeed(selectedOption.value);
  }, [selectedOption.value]);

  // If a "targetTime" is present in playerState, we'll create a tick for it
  if (
    !!playerState.targetTime &&
    showTick &&
    (!playerState.targetDuration || playerState.targetDuration == 0)
  ) {
    var eventTimePct =
      (100 * playerState.frontPad) / (playerState.frontPad + playerState.backPad);
  }

  const secondsElapsed = Math.floor((playerState.progress * duration) / 100);

  const ref = useRef();

  return (
    <Container
      style={{ ...absoluteLayout, backgroundColor: theme.colors.dark.background }}
      padding={3}
    >
      <Row>
        <Container ref={ref} flexGrow style={{ position: 'relative' }}>
          <Container paddingBottom={1}>
            <VideoClipSlider
              ref={ref}
              duration={duration}
              {...playerState}
              trim={{
                lead: playerState.leadTrim,
                trail: playerState.trailTrim,
              }}
              handleVideoProgress={handleVideoProgress}
              handleVideoTrim={handleVideoTrim}
              setFrontPad={setFrontPad}
              setBackPad={setBackPad}
              showEditControls={clipTrimControlsToggled}
            />
          </Container>
          {!!eventTimePct && (
            <InputTick
              eventTimePct={eventTimePct}
              onClick={() => handleVideoProgress({ target: { value: eventTimePct } })}
            />
          )}
        </Container>
        {height <= theme.breakpoints.xs && (
          <Container paddingLeft={2}>
            <IconButton
              icon={<Pin />}
              color={
                controlsPinned
                  ? theme.colors.teams.bos.primary
                  : getColorWithOpacity(theme.colors.dark.text.primary, 0.8)
              }
              hoverColor={
                controlsPinned
                  ? theme.colors.teams.bos.primary
                  : theme.colors.dark.text.primary
              }
              size={25}
              onClick={() => setControlsPinned(!controlsPinned)}
            />
          </Container>
        )}
      </Row>
      <Column gap={2}>
        <Row columnGap={1}>
          <IconButton
            icon={<SkipBackwardFill />}
            color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
            hoverColor={getColorWithOpacity(theme.colors.dark.text.primary, 1)}
            size={25}
            onClick={() => {
              playerState.progress < 1 ? setPreviousClip() : handleVideoProgress(0);

              if (playerState.isPlaying) {
                togglePlay();
              }
            }}
          />
          <IconButton
            icon={playerState.isPlaying ? <PauseFill /> : <PlayFill />}
            color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
            hoverColor={getColorWithOpacity(theme.colors.dark.text.primary, 1)}
            size={30}
            onClick={togglePlay}
          />
          <IconButton
            icon={<SkipForwardFill />}
            color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
            hoverColor={getColorWithOpacity(theme.colors.dark.text.primary, 1)}
            size={25}
            onClick={() => setNextClip()}
          />
          <IconButton
            icon={playerState.isMuted ? <VolumeMuteFill /> : <VolumeUpFill />}
            color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
            hoverColor={getColorWithOpacity(theme.colors.dark.text.primary, 1)}
            size={30}
            onClick={toggleMute}
          />
          {(playerState.playbackMode == 'fullclip' ||
            (!!playerState.targetDuration && playerState.targetDuration >= 0)) && (
            <IconButton
              icon={<ArrowCounterclockwise />}
              color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
              hoverColor={theme.colors.dark.text.primary}
              size={25}
              onClick={() => seekToSeconds(playerState.currentTime - 10)}
            />
          )}
          {(playerState.playbackMode == 'fullclip' ||
            (!!playerState.targetDuration && playerState.targetDuration >= 0)) && (
            <IconButton
              icon={<ArrowClockwise />}
              color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
              hoverColor={theme.colors.dark.text.primary}
              size={25}
              onClick={() => seekToSeconds(playerState.currentTime + 10)}
            />
          )}
          <HoverInteractive content={buttonGroup}>
            <IconButton
              icon={<Speedometer2 />}
              color={getColorWithOpacity(theme.colors.dark.text.primary, 0.8)}
              hoverColor={theme.colors.dark.text.primary}
              size={25}
            />
          </HoverInteractive>
        </Row>
        <Row columnGap={1}>
          {playerState.progress > 0 && (
            <Typography variant="body1" color={theme.colors.dark.text.primary}>
              {formatSeconds(secondsElapsed)}
            </Typography>
          )}
          <Typography variant="body2" color={theme.colors.dark.text.primary}>
            {!!currentClip && (
              <>
                {formatGameTime(currentClip.gameTime - playerState.frontPad)} to{' '}
                {
                  formatGameTime(
                    currentClip.gameTime + playerState.backPad + duration
                  ).split(' ')[1]
                }
              </>
            )}
          </Typography>
          {(!playerState.isReady || playerState.buffering) && (
            <Typography variant="body2" color={theme.colors.dark.text.primary}>
              Buffering...
            </Typography>
          )}
        </Row>
      </Column>
      <VideoClipKeyboardControls />
    </Container>
  );
};

const VideoClipKeyboardControls = ({}) => {
  const { playerState, togglePlay, handleVideoProgress, seekToSeconds } =
    useContext(VideoPlayerContext);

  const { setNextClip, setPreviousClip } = useContext(VideoClipContext);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.code == 'Space' && !['text', 'textarea'].includes(event.target.type)) {
        event.preventDefault();
        togglePlay();
      } else if (event.code == 'ArrowLeft') {
        event.preventDefault();
        seekToSeconds(playerState.currentTime - 0.25);
      } else if (event.code == 'ArrowRight') {
        event.preventDefault();
        seekToSeconds(playerState.currentTime + 0.25);
      } else if (event.code == 'ArrowDown') {
        event.preventDefault();
        setNextClip();
      } else if (event.code == 'ArrowUp') {
        event.preventDefault();
        setPreviousClip();
      }
    },
    [playerState.currentTime, playerState.progress, playerState.isPlaying]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
};
