import { roundToX } from 'helpers/data';
import {
  getFutureSeasonChoices,
  getGameTypeChoices,
  getSeasonChoices,
} from 'helpers/filters';
import { formatMinutes } from 'helpers/helpers';
import { getSeasonPlusMinusYears, season8to4 } from 'helpers/hockeyutils';

export const SKATER_COLUMNS_KEY = 'cfPlayerContractsSkaterColumnIds';
export const GOALIE_COLUMNS_KEY = 'cfPlayerContractsGoalieColumnIds';

export const positionOptions = [
  'All Skaters',
  'Forwards',
  'Defensemen',
  'Wingers',
  'C',
  'LW',
  'RW',
  'RD',
  'LD',
  'G',
].map((p) => ({
  label: p,
  value: p,
}));

export const seasonOptions = [...getSeasonChoices('NHL', true)];
export const generalSeasonOptions = getFutureSeasonChoices(4)
  .reverse()
  .concat(seasonOptions);

export const expiryYearSeasonOptions = seasonOptions
  .concat(getFutureSeasonChoices(9))
  .sort((a, b) => b.value - a.value);

export const contractTypeOptions = [
  {
    value: 'EntryLevel',
    label: 'Entry Level',
  },
  {
    value: 'Standard',
    label: 'Standard',
  },
  {
    value: 'ThirtyFivePlus',
    label: '35+',
  },
];

export const clauseDetailOptions = [
  {
    value: null,
    label: 'None',
  },
  {
    value: 'Full',
    label: 'Full',
  },
  {
    value: 'Modified',
    label: 'Modified',
  },
];

export const signingStatusOptions = [
  {
    value: 'ELC',
    label: 'ELC',
  },
  {
    value: 'UFA',
    label: 'UFA',
  },
  {
    value: 'RFA',
    label: 'RFA',
  },
];

export const expiryStatusOptions = [
  {
    value: 'UFA',
    label: 'UFA',
  },
  {
    value: 'RFA',
    label: 'RFA',
  },
  {
    value: '10.2(c)',
    label: '10.2(c)',
  },
];

export const expiryStatusDetailsOptions = [
  {
    value: 'GroupSix',
    label: 'GroupSix',
  },
  {
    value: 'Arbitration Eligible',
    label: 'Arb. Eligible',
  },
  {
    value: 'This player has not accrued the necessary amount of professional seasons required for Group 2 RFA status. They are ineligible for an offer sheet. Please visit the CBA FAQ for more information',
    label: '10.2(c)',
  },
];

export const generalFilterOptions = [
  {
    value: 'active-players',
    label: 'Active Players',
  },
  {
    value: 'free-agents',
    label: 'Free Agents',
  },
  {
    value: 'ufas',
    label: 'UFAs',
  },
  {
    value: 'rfas',
    label: 'RFAs',
  },
  {
    value: 'elcs',
    label: 'ELCs',
  },
  {
    value: 'all',
    label: 'All Contracts',
  },
];

export const handednessOptions = [
  {
    value: 'L',
    label: 'Left',
  },
  {
    value: 'R',
    label: 'Right',
  },
];

export const booleanOptions = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const getTimeRangeOptions = (season) => [
  ...(!!season
    ? [
        {
          value: 'season-stats',
          label: `${season8to4(season, true)} Season`,
        },
      ]
    : []),
  {
    value: 'multiple-season-stats',
    label: 'Custom Seasons',
    renderLabel: (timeRange) => {
      const targetSeason = season8to4(timeRange['season'].value, false);
      const startSeason = season8to4(
        getSeasonPlusMinusYears(
          timeRange['season'].value,
          -timeRange['platform-prior-seasons']?.value || 0
        ),
        false
      );

      return targetSeason == startSeason
        ? season8to4(timeRange['season'].value, true)
        : `${startSeason}-${targetSeason}`;
    },
  },
  {
    value: 'custom-sl-stats',
    label: 'Custom SL Query',
    renderLabel: (timeRange) => {
      //TODO: This logic has been done elsewhere...
      const slFilters = timeRange['custom_sl_filters'].value.filters;
      const seasonLabel =
        'season' in slFilters
          ? slFilters['season'].map((s) => season8to4(s, true)).join(', ')
          : '';

      const gameTypeLabel =
        'game_type' in slFilters
          ? getGameTypeChoices('nhl').find((gt) => gt.value == slFilters['game_type'])
              ?.label
          : '';

      return `${seasonLabel} ${gameTypeLabel}`;
    },
  },
  {
    value: 'career-stats',
    label: 'Career',
  },
  {
    value: 'platform-stats',
    label: 'Platform Stats',
  },
  {
    value: 'career-platform-stats',
    label: 'Career to Platform Stats',
  },
];

export const useAnalysisStatsForTimeRange = (timeRange) =>
  timeRange == 'platform-stats' || timeRange == 'career-platform-stats';

export const useSeasonFilterRange = (timeRange) =>
  timeRange == 'platform-stats' || timeRange == 'season-stats';

export const sourceOptions = [
  {
    value: 'nhl',
    label: 'NHL',
  },
  {
    value: 'sl',
    label: 'SL',
  },
];

export const statValueFilters = (position) => [
  {
    id: 'GP',
    scale: (timeRange) => ({
      min: 0,
      max: useSeasonFilterRange(timeRange) ? 90 : 1200,
    }),
  },
  ...(position == 'G'
    ? [
        {
          id: 'SV%',
          scale: () => ({
            min: 0,
            max: 100,
          }),
        },
        {
          id: 'W',
          scale: () => ({
            min: 0,
            max: 82,
          }),
        },
      ]
    : [
        {
          id: 'G',
          scale: (timeRange) => ({
            min: 0,
            max: useSeasonFilterRange(timeRange) ? 80 : 900,
          }),
        },
        {
          id: 'A',
          scale: (timeRange) => ({
            min: 0,
            max: useSeasonFilterRange(timeRange) ? 80 : 2000,
          }),
        },
        {
          id: 'P',
          scale: (timeRange) => ({
            min: 0,
            max: useSeasonFilterRange(timeRange) ? 130 : 2900,
          }),
        },
        {
          id: 'P/GP',
          scale: () => ({
            min: 0,
            max: 2.5,
          }),
          step: 0.01,
          formatLabel: (label) => roundToX(label, 2),
        },
        {
          id: 'TOI/GP',
          scale: () => ({
            min: 0,
            max: 40,
          }),
          // step: 0.01,
          formatLabel: (label) => formatMinutes(label),
        },
      ]),
];

export const slStatValueFilters = (position) => [
  ...(position == 'G'
    ? [
        {
          id: 'GBR',
          scale: () => ({
            min: 0,
            max: 100,
          }),
        },
      ]
    : [
        {
          id: 'XPGF',
          scale: (timeRange) => ({
            min: 0,
            max: useSeasonFilterRange(timeRange) ? 100 : 2000,
          }),
        },
        {
          id: 'PBR',
          scale: () => ({
            min: 0,
            max: 100,
          }),
        },
      ]),
];

export const seasonPhaseOptions = [
  {
    value: 'INSEASON',
    label: 'In-Season',
  },
  {
    value: 'DRAFT',
    label: 'Draft',
  },
  {
    value: 'OFFSEASON',
    label: 'Offseason',
  },
  {
    value: 'POSTDEADLINE',
    label: 'Post Deadline',
  },
  {
    value: 'UNKNOWN',
    label: 'Unknown',
  },
];

export const playerStatusOptions = [
  {
    value: 'Nhl',
    label: 'NHL',
  },
  {
    value: 'Minor',
    label: 'Minors',
  },
  {
    value: 'Junior',
    label: 'Juniors',
  },
  {
    value: 'InjuredReserve',
    label: 'IR',
  },
  {
    value: 'LongTermInjuredReserve',
    label: 'LTIR',
  },
  {
    value: 'SeasonOpeningInjuredReserve',
    label: 'Season Opening IR',
  },
  {
    value: 'Suspended',
    label: 'Suspended',
  },
  {
    value: 'Loan',
    label: 'Loan',
  },
  {
    value: 'EmergencyLoan',
    label: 'Emergency Loan',
  },
  {
    value: 'NhlNonRoster',
    label: 'NHL Non-Roster',
  },
  {
    value: 'NhlNonRosterNoCapHit',
    label: 'NHL Non-Roster No Cap Hit',
  },
  {
    value: 'NhlpaPlayerAssistanceProgram',
    label: 'NHLPA Assistance Program',
  },
];
