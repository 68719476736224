import { immer } from 'zustand/middleware/immer';
import { createContext, useContext, useEffect, useRef } from 'react';
import { create, useStore } from 'zustand';
import { buildSearchParams, parseSearchParams } from './helpers';

const FilterContext = createContext();
const createStore = ({ onChange, defaults, searchParamFilters }) =>
  create(
    immer((set, get) => ({
      defaults,
      filters: searchParamFilters || defaults || {},
      showGroups: {},
      tags: {},
      actions: {
        setFilters: (id, filter) => {
          set((state) => {
            state.filters[id] = filter;
          });

          onChange && onChange(get().filters);
        },
        removeFilter: (id) => {
          set((state) => {
            if (id in state.filters) {
              delete state.filters[id];
            }
          });

          onChange && onChange(get().filters);
        },
        removeFilters: (cond) => {
          set((state) => {
            state.filters = Object.fromEntries(
              Object.values(state.filters)
                .filter(cond)
                .map((f) => [f.id, f])
            );
          });

          onChange && onChange(get().filters);
        },
        clearFilters: () => {
          set((state) => {
            state.filters = get().defaults;
          });

          onChange && onChange(get().filters);
        },
        setShowGroup: (name, showGroup) => {
          set((state) => {
            state.showGroups[name] = showGroup;
          });
        },
        showAllGroups: (show = true) => {
          set((state) => {
            Object.keys(state.showGroups).forEach(
              (key) => (state.showGroups[key] = show)
            );
          });
        },
        registerFilterTag: (id, tag) => {
          set((state) => {
            state.tags[id] = tag;
          });
        },
        replaceFilters: (filters) => {
          set((state) => {
            state.filters = filters;
          });

          onChange && onChange(get().filters);
        },
      },
    }))
  );

export const FilterContextProvider = ({
  children,
  onChange,
  defaults,
  useSearchParams,
  searchParamKey,
}) => {
  const searchParamFilters = useSearchParams && parseSearchParams();
  const store = useRef(createStore({ onChange, defaults, searchParamFilters })).current;

  return (
    <FilterContext.Provider value={store}>
      {children}
      <SearchParamsListener
        useSearchParams={useSearchParams}
        searchParamKey={searchParamKey}
      />
    </FilterContext.Provider>
  );
};

export const useFilterContext = (selector) =>
  useStore(useContext(FilterContext), selector);

const SearchParamsListener = ({ useSearchParams, searchParamKey = 'filters' }) => {
  const filters = useFilterContext((state) => state.filters);

  useEffect(() => {
    if (!useSearchParams) return;
    buildSearchParams(filters, { key: searchParamKey });
  }, [filters]);
};

export const useFilterValues = () => {
  const filters = useFilterContext((state) => state.filters);
  return Object.fromEntries(Object.values(filters).map((f) => [f.id, f.value]));
};
