import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'constants';
import BDDSortableTable from 'components/bdd/bddsortabletable';
import useToggle from 'components/bdd/Toggle/useToggle';
import { useSelect } from 'components/bdd/Select';

import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import { useCareerStats } from './hooks/useCareerStats';
import { Column, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { truncateString } from 'helpers/string';
import { roundToX } from 'helpers/data';

const DEFAULT_VISIBLE_SEASONS = 5; // how many seasons do we show by default

const highlight = {
  boxShadow: `
    inset 0px 1px 0px 0px ${theme.colors.teams.bos.primary},
    inset 0px -1px 0px 0px ${theme.colors.teams.bos.primary}`,
};

const stickyPosition = {
  position: 'sticky',
  left: 0,
};

const ShowRowsPrompt = styled.div({
  ...theme.typography.body4,
  cursor: 'pointer',
  ':hover': {
    textDecoration: 'underline',
    color: '#333',
  },
});

const TableStyles = styled.div({
  // paddingBottom: '10px',
  thead: {
    'tr:hover': {
      border: 0,
      boxShadow: 'none',
    },
    'th:first-child': {
      ...stickyPosition,
      backgroundColor: theme.colors.light.background,
    },
  },
  tfoot: {
    ...theme.borders.light,
    ...theme.borders.thin,
    ...theme.borders.top,
  },
  th: {
    ...theme.borders.light,
    ...theme.borders.thin,
    ...theme.borders.bottom,
    paddingRight: theme.spacing[3],
  },
  tr: {
    ':last-child': {
      td: {
        borderBottom: 0,
      },
    },
  },
  'td:first-child': {
    ...stickyPosition,
    backgroundColor: theme.colors.light.background,
  },
  'tr.subRow': {
    color: theme.colors.light.text.disabled,
  },
  'tr:nth-of-type(even)': {
    backgroundColor: theme.colors.light.secondary,
    'td:first-child': {
      ...stickyPosition,
      backgroundColor: theme.colors.light.secondary,
    },
  },
  'tr:hover': {
    ...highlight,
    'td:first-child': {
      ...highlight,
    },
  },
});

const Container = styled.div({
  overflow: 'auto',
  width: '100%',
});

const TableHeaderTitle = styled.span({
  ...theme.typography.body1,
  color: theme.colors.light.text.secondary,
  userSelect: 'none',
});

const Label = styled.div({
  ...theme.typography.body2,
  display: 'flex',
  alignItems: 'center',
});

const ControlsContainer = styled.div({
  ...stickyPosition,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'start',
  alignItems: 'center',
  columnGap: theme.spacing[5],
});

const CompactCareerFooter = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing[1],
});

const getTeamShort = (row) => {
  // Data from the NHL API will have a "team_shorthand" if its in the NHL, but otherwise will just have a "team_name"
  // we truncate NHL team names because NHL data is very wide
  // finally, EP data uses row.team.name and doesn't need to be truncated
  return !!row.team_shorthand 
    ? row.team_shorthand 
    : !!row.team_name
    ? truncateString(row.team_name, 10)
    :  row.team?.name;
};
const getLeague = (row) => {
  // NHL data has "leagueAbbrev", EP data has "leagueName"
  if (!row){
    return null
  }
  return !!row.leagueAbbrev ? row.leagueAbbrev : row.leagueName;
}

const strToiToSeconds = (toiStr) => {
  if (typeof(toiStr) == 'number') return toiStr;
  const [min, sec] = toiStr.split(':').map((x) => parseInt(x));
  return min * 60 + sec;
};

const toiToPerGame = (toi, gp) => {
  if (!toi) return null;
  const toiSec = strToiToSeconds(toi);
  const secPerGP = toiSec / parseFloat(gp);
  return toiSecToString(secPerGP);
};

const toiSecToString = (toiSec) => {
  let min = Math.floor(toiSec / 60);
  let sec = Math.floor(toiSec % 60);

  return `${min}:${sec.toFixed(0).padStart(2, '0')}`;
};

const toiMinToString = (toiMin) => {
  let min = Math.floor(toiMin);
  let sec = Math.floor(toiMin * 60 % 60);

  return `${min}:${sec.toFixed(0).padStart(2, '0')}`;
};


const getSkaterColumns = (nhlDataAvailable) => ({
  GP: { value: 'gamesPlayed' },
  G: { value: 'goals' },
  A: { value: 'assists' },
  P: { value: 'points' },
  ...(nhlDataAvailable && {
    PPP: { value: 'powerPlayPoints' },
    'TOI/GP': {
      value: (d) => d.avgToi,
      minWidth: 50,
    },
    'PP TOI/GP': {
      value: (d) => toiToPerGame(d.toiPP*60, d.gamesPlayed),
      minWidth: 70,
    },
  }),
  ...(nhlDataAvailable && {
    '+/-': { value: 'plusMinus' },
    'ES TOI/GP': {
      value: (d) => toiToPerGame(d.toiES*60, d.gamesPlayed),
      minWidth: 70,
    },
    'SH TOI/GP': {
      value: (d) => toiToPerGame(d.toiSH*60, d.gamesPlayed),
      minWidth: 70,
    },

    PPG: { value: 'powerPlayGoals' },
    SHG: { value: 'shortHandedGoals' },
    SHP: { value: 'shortHandedPoints' },
    'FOW%': {
      value: (d) => (d.faceoffWinningPctg ? roundToX(100*parseFloat(d.faceoffWinningPctg), 2) : null),
      minWidth: 50,
    },
    'FAtt': { value: 'faceOffAttempts' },
    'SH%': {
      value: (d) => (d.shootingPctg ? roundToX(100*parseFloat(d.shootingPctg), 2) : null),
      minWidth: 50,
    },
  }),
  PIM: { value: 'pim' },
  ...(nhlDataAvailable && {
    H: { value: 'hits', minWidth: 40 },
    Bks: { value: 'blocks', minWidth: 40 },
  }),
});

const getGoalieColumns = (nhlDataAvailable) => (
  {
    GP: { value: 'gamesPlayed' },
    GAA: {
      value: (d) =>
        d.goalsAgainstAvg ? parseFloat(d.goalsAgainstAvg).toFixed(2) : null,
    },
    'SV%': {
      value: (d) =>
        d.savePctg ? (100 * parseFloat(d.savePctg)).toFixed(2) : null,
      minWidth: 50,
    },
    Starts: { value: 'gamesStarted', minWidth: 50 },
    W: { value: 'wins' },
    L: { value: 'losses' },
    OT: { value: 'otLosses' },
    Saves: { value: 'saves', minWidth: 40 },
    Shots: { value: 'shotsAgainst', minWidth: 50 },
    GA: { value: 'goalsAgainst', minWidth: 50 },
    ...(nhlDataAvailable && {
      'ES SV%': {
        value: (d) => roundToX(100 * d.savesES / d.shotsAgainstES, 2),
        minWidth: 60,
      },
      'SH SV%': {
        value: (d) => roundToX(100 * d.savesSH / d.shotsAgainstSH, 2),
        minWidth: 60,
      },
      'PP SV%': {
        value: (d) => roundToX(100 * d.savesPP / d.shotsAgainstPP, 2),
        minWidth: 60,
      }
    })
  }
);

const getTableColumns = (columns, totals) => {
  const tableColumns = Object.keys(columns).map((key, index) => {
    const getStatValue = (stats) => {
      return typeof columns[key].value == 'function'
        ? columns[key].value(stats)
        : stats[columns[key].value];
    };

    return {
      id: key,
      width: columns[key].minWidth || 30,
      minWidth: columns[key].minWidth || 30,
      accessor: (stats) => getStatValue(stats),
      Header: columns[key].Header || (
        <div style={{ display: 'flex' }}>
          {columns[key].includeExpand && (
            <div style={{ minWidth: index == 0 ? 20 : 0 }}></div>
          )}
          <TableHeaderTitle>{key}</TableHeaderTitle>
        </div>
      ),
      Cell: ({
        cell: {
          row: { canExpand, isExpanded, original: stats },
        },
      }) => (
        <Label>
          {columns[key].includeExpand && (
            <div style={{ minWidth: index == 0 ? 20 : 0 }}>
              {columns[key].includeExpand && canExpand ? (
                <div>{isExpanded ? <ChevronDown /> : <ChevronRight />}</div>
              ) : null}
            </div>
          )}
          {getStatValue(stats)}
        </Label>
      ),
      Footer: (
        <Label>
          {columns[key].includeExpand && (
            <div style={{ minWidth: index == 0 ? 20 : 0 }}></div>
          )}
          <b>{columns[key].footerComponent || getStatValue(totals)}</b>
        </Label>
      ),
    };
  });

  return [...tableColumns];
};

const getAggregatedRow = (stats, team = 'CAREER', league = 'CAREER') => {
  const getSum = (stat, compareStat) =>
    stats.reduce((sum, s) => {
      if (!s[stat] || (compareStat && !s[compareStat])) {
        return sum;
      }

      if (stat.endsWith('OnIce')) {
        return sum + strToiToSeconds(s[stat]);
      }

      return sum + s[stat];
    }, 0);

  const seasons = stats.map((s) => parseInt(s.season));
  const fullSeasonRange = `${Math.min(...seasons)
    .toString()
    .slice(0, 4)}${Math.max(...seasons)
    .toString()
    .slice(4)}`;

  return {
    season: fullSeasonRange,
    leagueAbbrev: league,
    team_shorthand: team,
    gamesPlayed: getSum('gamesPlayed'),
    avgToi:  toiMinToString(getSum('toi') / getSum('gamesPlayed')),
    // Skater stats
    goals: getSum('goals'),
    shots: getSum('shots'),
    assists: getSum('assists'),
    points: getSum('points'),
    plusMinus: getSum('plusMinus'),
    pim: getSum('pim'),
    powerPlayGoals: getSum('powerPlayGoals'),
    powerPlayPoints: getSum('powerPlayPoints'),
    timeOnIce: toiMinToString(getSum('toi')),
    toiES: getSum('toiES'),
    toiPP: getSum('toiPP'),
    toiSH: getSum('toiSH'),
    shortHandedGoals: getSum('shortHandedGoals'),
    shortHandedPoints: getSum('shortHandedPoints'),
    faceoffWinningPctg: getSum('faceOffWins') / getSum('faceOffAttempts'),
    faceOffAttempts: getSum('faceOffAttempts'),
    shootingPctg: getSum('goals') / getSum('shotsOnGoal'),
    hits: getSum('hits'),
    blocks: getSum('blocks'),

    // Goalie stats
    gamesStarted: getSum('gamesStarted'),
    wins: getSum('wins'),
    losses: getSum('losses'),
    otLosses: getSum('otLosses'),
    saves: getSum('saves'),
    shotsAgainst: getSum('shotsAgainst'),
    goalsAgainst: getSum('goalsAgainst'),
    goalsAgainstAvg: (getSum('goalsAgainst') / getSum('timeOnIce')) * 3600,
    savePctg: getSum('saves') / getSum('shotsAgainst'),
    savePctgES: (getSum('savesES') / getSum('shotsAgainstES')) * 100,
    savePctgSH: (getSum('savesSH') / getSum('shotsAgainstSH')) * 100,
    savePctgPP: (getSum('savesPP') / getSum('shotsAgainstPP')) * 100,
  };
};

const CareerStats = ({
  tableId,
  slug,
  epId,
  hideRows = true,
  defaultVisibleRows = DEFAULT_VISIBLE_SEASONS,
  compact,
  showFooter = true
}) => {
  const tableElement = useRef(null);
  const width = tableElement?.current?.offsetWidth;

  const [showAllData, setShowAllData] = useState(!hideRows);

  const { toggleComponent: nhlDataToggle, toggled: useNHL } = useToggle({
    id: `${tableId}-${slug}-nhl-data-toggle`,
    label: 'NHL data',
    initialToggled: true,
  });
  
  const { toggleComponent: onlyNHLToggle, toggled: onlyNHL, forceSetToggle: forceSetOnlyNHL } = useToggle({
    id: `${tableId}-${slug}-nhl-stats-toggle`,
    label: 'NHL stats only',
    initialToggled: true,
    disabled: !useNHL
  });

  const { selected: gameType, select: gameTypeSelect } = useSelect({
    options: [
      { value: 'regular', label: 'Regular Season' },
      { value: 'playoffs', label: 'Playoffs' },
    ],
    initialSelectedValue: 'regular',
    variant: 'outlined',
  });

  let totals;
  let groupedStats;
  const { nhlData, stats, isGoalie, leaguesWithGamesPlayed, placeholder } =
    useCareerStats({
      slug,
      epId,
      gameType: gameType.value,
      onlyNHL,
      useNHL
    });

  // Once data loaded, if we have no NHL data, but do have data for other leagues, force set "Only NHL" toggle to off
  useEffect(() => {
    if (!!nhlData && stats.length === 0) {
      forceSetOnlyNHL(false)
    }
  }, [placeholder])


  const leagueOptions = [...new Set(stats?.map((s) => getLeague(s)))].map(
    (leagueName) => ({
      value: leagueName,
      label: leagueName,
    })
  );
  const mostRecentLeague = stats ? getLeague(stats[stats.length -1]) : null

  // Start with NHL or most recent league
  const initialSelectedLeagueName =
    nhlData && onlyNHL
      ? 'NHL'
      : mostRecentLeague;

  const { selected: careerLeague, select: careerLeagueSelect } = useSelect({
    options: leagueOptions,
    initialSelectedValue: initialSelectedLeagueName,
    variant: 'outlined',
    size: 'small',
    updateOnOptionsChange: true,
    collapseIndicator: true
  });

  if (stats) {
    let statsByLeague = stats.filter(
      (s) => getLeague(s) == (careerLeague?.value || initialSelectedLeagueName)
    );

    totals = getAggregatedRow(
      statsByLeague,
      'CAREER',
      careerLeague?.value || initialSelectedLeagueName
    );

    groupedStats = stats.reduce((gs, cs) => {
      const groupExists = gs.find(
        (s) => s.season == cs.season && getLeague(s) == getLeague(cs)
      );

      if (groupExists) {
        return gs;
      }

      const statsForSeasonLeague = stats.filter(
        (s) => s.season == cs.season && getLeague(s) == getLeague(cs)
      );

      let parentRow = statsForSeasonLeague[0];
      let subRows = statsForSeasonLeague.map(s => ({ ...s }));

      // Combine records for the parent row
      if (statsForSeasonLeague.length > 1) {
        parentRow = getAggregatedRow(
          statsForSeasonLeague,
          statsForSeasonLeague.map((s) => getTeamShort(s)).join('/'),
          getLeague(cs)
        );
      } else {
        // Remove original parent row
        subRows = subRows.filter((s) => s.teamName.default != cs.teamName.default);
      }

      // Mark each subrow
      subRows.forEach((sr) => (sr.isSubRow = true));

      gs.push({
        ...parentRow,
        subRows,
      });

      return gs;
    }, []);
  }

  const standardColumns = {
    Season: {
      value: (d) => `${d.season.toString().slice(2, 4)}-${d.season.toString().slice(6)}`,
      includeExpand: true,
      minWidth: 70,
    },
    League: {
      value: (d) => getLeague(d),
      minWidth: 90,
      footerComponent: careerLeagueSelect,
    },
    Team: { value: (d) => getTeamShort(d), minWidth: 90 },
  };

  const compactStandardColumns = {
    SeasonLeagueTeam: {
      Header: <div />,
      value: (d) =>
        standardColumns.Team.value(d) == 'CAREER' ? (
          <CompactCareerFooter>
            <div style={{ minWidth: 35 }}>{standardColumns.Season.value(d)}</div>
            <div>|</div>
            <div>{careerLeagueSelect}</div>
          </CompactCareerFooter>
        ) : (
          <Container>
            <Row columnGap={1} flexWrap alignItems={'top'}>
              <div>{standardColumns.Season.value(d)}</div>
              <div>|</div>
              <div>
                <b>{standardColumns.League.value(d)}</b>
              </div>
              {standardColumns.Team.value(d)?.length <= 3 && <div>|</div>}
              <div>{standardColumns.Team.value(d)}</div>
            </Row>
          </Container>
        ),
      minWidth: onlyNHL ? 110 : 175,
      includeExpand: false,
    },
  };

  // Restrict to a subset to keep component reasonable in size (old data can be accessed thru click)
  const canHideSeasons =
    hideRows && !!groupedStats && groupedStats.length > defaultVisibleRows; // true if hiding is an option
  const areHidingSeasons = hideRows && canHideSeasons && !showAllData; // true if actively hiding
  let viewableData = areHidingSeasons
    ? groupedStats.slice(groupedStats.length - defaultVisibleRows)
    : groupedStats;
  return (
    <>
      <Container ref={tableElement}>
        {!compact && (
          <ControlsContainer>
            <Column>
              <Row flexWrap columnGap={1}>
                {gameTypeSelect}
                {nhlData && onlyNHLToggle}
                {nhlData && nhlDataToggle}
              </Row>
              {areHidingSeasons ? (
                <Row columnGap={1}>
                  <ShowRowsPrompt onClick={() => setShowAllData(true)}>
                    Showing {defaultVisibleRows} of {groupedStats.length} rows. Click to
                    show all
                  </ShowRowsPrompt>
                </Row>
              ) : (
                <Container>
                  <Typography variant="body4">All rows visible</Typography>
                </Container>
              )}
            </Column>
          </ControlsContainer>
        )}
        {viewableData ? (
          <TableStyles>
            <BDDSortableTable
              columnJustify="start"
              flexLayout
              data={viewableData}
              columns={getTableColumns(
                {
                  ...(width >= theme.breakpoints.xs
                    ? standardColumns
                    : compactStandardColumns),
                  ...(isGoalie
                    ? getGoalieColumns(!!nhlData && useNHL)
                    : getSkaterColumns(!!nhlData && useNHL)),
                },
                totals
              )}
              scroll={false}
              hasFooter={showFooter}
              trClassCallback={(row) => (!row.original.isSubRow ? '' : 'subRow')}
            />
          </TableStyles>
        ) : (
          placeholder
        )}
      </Container>
    </>
  );
};

export default CareerStats;