import { useState } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { GET_ALL_REPORTS } from 'apollo/queries/scouting.queries';
import FilterBank from './FilterBank';
import { useReportSearchParams } from './useReportsSearchParams';

export default function useReports({
  limit = 10,
  scoutingObjectiveSlug,
  defaultFilters = {},
  useSearchParams = true,
  hideDateRangeFilter,
}) {
  const {
    filters,
    setFilters,
    setSearchParams,
    parsedFilters,
    currRequestFilters,
    setCurrRequestFilters,
  } = useReportSearchParams(defaultFilters, !useSearchParams);

  const refetchReports = (freshFilters) => {
    queryResponse.refetch({
      limit,
      filters: freshFilters,
      scoutingObjectiveSlug,
    });
  };

  const queryResponse = useQuery(GET_ALL_REPORTS, {
    variables: {
      limit,
      filters: currRequestFilters,
      scoutingObjectiveSlug,
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    ...queryResponse,
    filterBankComponent: (
      <FilterBank
        scoutingObjectiveSlug={scoutingObjectiveSlug}
        filters={filters}
        setFilters={setFilters}
        requiresRefetch={parsedFilters !== currRequestFilters}
        loading={queryResponse.loading}
        hideDateRangeFilter={hideDateRangeFilter}
        handleFetch={() => {
          setCurrRequestFilters(parsedFilters);
          setSearchParams();
          refetchReports(parsedFilters);
        }}
      />
    ),
    filters,
    currRequestFilters,
    parsedFilters,
  };
}
