import { useQuery } from '@apollo/client';
import { GET_BF_TEAM_BREAKDOWN } from 'apollo/queries/bruinsfriendly.queries';
import { Column, Container } from 'components/bdd/Layout';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { TeamPlayerCapHitTables } from './TeamPlayerCapHitTable';
import TeamCapBrief from './TeamCapBrief';
import { TeamActiveRosterBrief } from './TeamActiveRosterBrief';

export default function TeamCapBreakdown({ teamSlug, showBrief = true }) {
  const { data, placeholder } = usePlaceholder(
    useQuery(GET_BF_TEAM_BREAKDOWN, {
      variables: { slTeamSlug: teamSlug },
    })
  );

  const bfPlayers = data?.bfTeam?.bfPlayers;
  const deadSpace = data?.bfTeam?.currentSeasonDeadSpace;

  if (placeholder) return placeholder;
  return (
    <Container paddingBottom={20}>
      {showBrief && <TeamActiveRosterBrief teamSlug={teamSlug} />}
      <TeamPlayerCapHitTables bfPlayers={bfPlayers} deadSpace={deadSpace} />
    </Container>
  );
}
