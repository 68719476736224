import { useMutation, useQuery } from '@apollo/client';
import { buildBDDDepthChartQuery } from 'apollo/queries/depthchart.queries';
import {
  GET_SCENARIO,
  UPDATE_PRO_OBJECTIVE_SCENARIO,
} from 'apollo/queries/proobjective.queries';
import { toastBddApiError } from 'components/bdd/bddtoasts';
import { useMemo } from 'react';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { buildScenarios } from './helpers';

export const useScenario = ({
  id,
  allTargets,
  cacheOnly=true, // if true, will only fetch slow queries thru cache (team strength and depth checks)
  skip,
}) => {
  const { data, placeholder, loading, networkStatus, refetch } = usePlaceholder(
    useQuery(GET_SCENARIO, {
      variables: {
        id,
        includeCapSheet: true,
        includeCapBreakdown: true,
        includeChecks: true,
        includeTeamStrength: true,
        cacheOnly,
      },
      notifyOnNetworkStatusChange: true,
      skip: !id || !!skip,
    })
  );
  const [updateScenario, { loading: updateScenarioLoading }] = useMutation(
    UPDATE_PRO_OBJECTIVE_SCENARIO,
    {
      onError: toastBddApiError,
    }
  );
  const { data: dcData } = useQuery(buildBDDDepthChartQuery({ includeCapSheet: true }), {
    variables: {
      teamSlug: data?.proObjectiveScenario?.teamSlug,
      key: data?.proObjectiveScenario?.baseDepthChartUniqueKey,
    },
    skip: !data,
  });

  // For now, don't use "base" data since DC's are in no shape... just use the scenario compared to recent games in league
  const baseAgentBasedData = null;


  const activeDc = dcData?.bddDepthChart;

  const transactions = data?.proObjectiveScenario?.transactions.map(t => t.transaction);

  const scenario = useMemo(
    () => buildScenarios({
      scenarios: [data?.proObjectiveScenario],
      baseDepthChart: activeDc,
      transactions,
      allTargets
    }), 
    [
      data,
      allTargets,
      transactions,
      activeDc,
    ]
  )?.[0];

  return {
    scenario,
    scenarioPlaceholder: placeholder,
    loading,
    networkStatus,
    updateScenario,
    updateScenarioLoading,
    refetch,
    refetchBaseAgentData: () => {}
  };
};
