import Icon from 'components/bdd/Icon';
import { Border, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import BDDTag from 'components/PlayerTagging/bddtag';
import { theme } from 'constants/theme';
import { ArrowDown, ArrowDownCircle, ArrowDownCircleFill } from 'react-bootstrap-icons';

export const BFWaiverTag = ({ bfPlayer, ...tagProps }) => {
  if (!bfPlayer || !!bfPlayer.waiversRequired) return null;

  return (
    <BDDTag
      tag={{
        color: theme.colors.states.success,
        textColor: theme.colors.light.text.primary,
        tag: (
          <Typography variant="body1">
            <Icon color={theme.colors.light.background} icon={<ArrowDownCircle />} />
          </Typography>
        ),
        description: 'Waivers Exempt',
      }}
      {...tagProps}
    />
  );
};
