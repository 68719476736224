const getColorWithOpacity = (color, opacity) => {
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

const font = {
  base: {
    fontFamily: 'Oswald',
  },
  system: {
    fontFamily: 'system-ui',
  },
  note: {
    fontFamily: 'system-ui',
  },
  weight: {
    regular: {
      fontWeight: 400,
    },
    thin: {
      fontWeight: 300,
    },
  },
  italic: {
    fontStyle: 'italic',
  },
};

const typography = {
  h1: {
    ...font.base,
    fontSize: 89,
    '@media (maxWidth: 1400px)': {
      fontSize: 45,
    },
  },
  h2: {
    ...font.base,
    fontSize: 60,
  },
  h3: {
    ...font.base,
    fontSize: 45,
    '@media (maxWidth: 1400px)': {
      fontSize: 34,
    },
  },
  h4: {
    ...font.base,
    fontSize: 34,
    '@media (max-height: 500px), (max-width: 1400px)': {
      fontSize: 24,
    },
  },
  h5: {
    ...font.base,
    fontSize: 20,
    '@media (max-height: 500px), (max-width: 1400px)': {
      fontSize: 18,
    },
  },
  h6: {
    ...font.base,
    fontSize: 18,
    '@media (max-height: 500px), (max-width: 1400px)': {
      fontSize: 14,
    },
  },
  subtitle1: {
    ...font.base,
    fontSize: 16,
    '@media (max-height: 500px), (max-width: 1400px)': {
      fontSize: 14,
    },
  },
  subtitle2: {
    ...font.base,
    ...font.weight.thin,
    fontSize: 16,
    letterSpacing: 0.2,
    '@media (max-height: 500px), (max-width: 1400px)': {
      fontSize: 14,
    },
  },
  body1: {
    ...font.base,
    ...font.weight.regular,
    fontSize: 14,
    '@media (max-height: 500px), (max-width: 1400px)': {
      fontSize: 12,
    },
  },
  body2: {
    ...font.base,
    ...font.weight.thin,
    fontSize: 14,
    letterSpacing: 0.2,
    '@media (max-height: 500px), (max-width: 1400px)': {
      fontSize: 12,
    },
  },
  body3: {
    ...font.note,
    fontSize: 14,
    '@media (max-height: 500px), (max-width: 1400px)': {
      fontSize: 12,
    },
  },
  body4: {
    ...font.system,
    fontSize: 12,
  },
  stat: {
    ...font.base,
    ...font.weight.thin,
    fontSize: 12,
    '@media (max-height: 500px), (max-width: 1400px)': {
      fontSize: 10,
    },
  },
  caption: {
    ...font.system,
    fontSize: 11,
  },
  error: {
    ...font.base,
    fontSize: 12,
    color: '#dc2626',
  },
  label: {
    ...font.base,
    fontSize: 12,
    color: '#5d5d5d',
    '@media (max-height: 500px), (max-width: 1400px)': {
      fontSize: 10,
    },
  },
};

const colors = {
  light: {
    background: '#FFFFFF',
    secondary: '#F1F1F1',
    border: '#C4C4C4',
    disabled: '#e7e7e7',
    highlight: '#FFD885',
    focus: '#ffedc6',
    text: {
      primary: '#35363A',
      secondary: '#575757',
      disabled: '#777',
      green: '#15803d',
    },
  },
  dark: {
    // background: '#35363A',
    background: '#171717',
    highlight: '#575757',
    focus: '#5f5f5f',
    text: {
      primary: '#FFFFFF',
      secondary: '#C4C4C4',
    },
  },
  teams: {
    bos: {
      primary: '#ffb81c',
    },
  },
  states: {
    success: '#16a34a',
    danger: '#dc2626',
    warning: '#ffbc00',
    neutral: '#646C78',
    link: '#007bff',
    background: {
      success: '#86efac',
      warning: '#FFD885',
      danger: '#fca5a5',
      neutral: '#d1d5db',
    },
  },
  categorical: {
    blue: '#4674b6',
    red: '#d34539',
    purple: '#9333ea',
  },
};

const spacing = {
  0.5: 2,
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 20,
  6: 24,
  7: 28,
  8: 32,
  9: 36,
  10: 40,
  11: 44,
  12: 48,
  14: 56,
  16: 64,
  20: 80,
  24: 96,
  28: 112,
  32: 128,
  36: 144,
  40: 160,
  44: 176,
  48: 192,
};

const borders = {
  none: {
    border: 0,
  },
  light: {
    borderColor: colors.light.border,
  },
  selected: {
    borderColor: colors.light.highlight,
    backgroundColor: getColorWithOpacity(colors.light.highlight, 0.3),
    borderWidth: 2,
    boxShadow: `0px 0px 8px ${colors.light.highlight}`,
  },
  dark: {
    borderColor: colors.dark.highlight,
  },
  transparent: {
    borderColor: 'transparent',
  },
  thin: {
    borderWidth: 1,
    borderStyle: 'solid',
    boxSizing: 'border-box',
  },
  horizontal: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
  },
  bottom: {
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
  },
  right: {
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
  top: {
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderRightWidth: 0,
  },
  roundedSmall: {
    borderRadius: spacing[0.5],
  },
  roundedMedium: {
    borderRadius: spacing[2],
  },
};

const buttons = {
  base: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    background: 'none',
    border: 0,
    paddingLeft: 0,
    userSelect: 'none',
  },
};

const breakpoints = {
  xs: 575,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
  xxl: 1600,
};

const cards = {
  medium: {
    ...borders.roundedMedium,
    boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
  },
};

const heights = {
  topbar: spacing[16],
};

buttons.link = (color, underline = 'text') => ({
  ...buttons.base,
  ...font.base,
  ...typography.body1,
  color,
  '&: hover': {
    ...theme.font.weight.regular,
    ...(underline == 'text'
      ? {
          textDecoration: 'underline',
          textDecorationColor: color,
        }
      : underline == 'border'
      ? { ...theme.borders.thin, ...theme.borders.bottom }
      : {
          a: {
            textDecoration: 'none',
          },
        }),
    color,
  },
});

const shadows = {
  card: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
  light: '0 10px 15px 0px rgb(0 0 0 / 0.1)',
  even: '0 0 5px #ddd',
};

export const theme = {
  font,
  typography,
  spacing,
  colors,
  borders,
  buttons,
  breakpoints,
  cards,
  heights,
  shadows,
};
