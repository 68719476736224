import Icon from 'components/bdd/Icon';
import { Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { TooltipSpan } from 'components/reports';
import { getNHLTeamLogoUrl } from 'helpers/logos';
import { ArrowLeftRight, Asterisk } from 'react-bootstrap-icons';

export const DraftPickIcon = ({
  // used on team card
  draftPick,
  height = '20px',
  isTraded,
}) => {
  const nhlid = draftPick.originalTeam.nhlid;

  const player = draftPick.player;
  const isSelected = !!player;
  const isConditional = !!draftPick.isConditional;

  const tooltipContent = (
    <>
      <div>
        R{draftPick.round} P{draftPick.overall}
      </div>
      <div>{isTraded ? `Traded to ${draftPick.currentTeam.abbreviation}` : ''}</div>
      <div>{isSelected ? `Selected ${player.firstName} ${player.lastName}` : ''}</div>
      <div>
        {!!draftPick.conditionalDetails ?
          `${draftPick.conditionalDetails.replace(/&#039;/g, "'")}` : ''}
      </div>
    </>
  );

  return (
    <Container style={{ textAlign: 'center', position: 'relative' }}>
      <TooltipSpan content={tooltipContent} placement="bottom">
        {!!isConditional && (
          <Icon
            icon={<Asterisk />}
            size="8px"
            style={{ position: 'absolute', top: 0, right: 0 }}
          />
        )}
        <Typography variant="caption">
          <img
            height={height}
            src={getNHLTeamLogoUrl(nhlid)}
            alt={`${draftPick.originalTeam.abbreviation} R${draftPick.round}`}
            style={{
              ...(isTraded && { opacity: '20%' }),
            }}
          />
        </Typography>
      </TooltipSpan>
    </Container>
  );
};

export const GeneralPickIcon = ({
  // used in different contexts
  draftPick,
  height = '20px',
  showTooltip = true,
  fadeLogo = false,
}) => {
  const nhlid = draftPick.originalTeam.nhlid;

  const player = draftPick.player;
  const isSelected = !!player;
  const isConditional = !!draftPick.isConditional;
  const isTraded = draftPick.currentTeamSlug !== draftPick.originalTeamSlug;

  const tooltipContent = (
    <>
      <div>
        R{draftPick.round} P{draftPick.overall}
      </div>
      <div>{isTraded && `Originally ${draftPick.originalTeam.abbreviation}, now ${draftPick.currentTeam.abbreviation}`}</div>
      <div>{isSelected && `Selected ${player.firstName} ${player.lastName}`}</div>
      <div>{!!draftPick.conditionalDetails ? `${draftPick.conditionalDetails}` : ''}</div>
    </>
  );

  return (
    <Row>
      <Container style={{ textAlign: 'center', position: 'relative' }}>
        <TooltipSpan content={showTooltip && tooltipContent}>
          {!!isConditional && (
            <Icon
              icon={<Asterisk />}
              size="8px"
              style={{ position: 'absolute', top: 0, right: 0 }}
            />
          )}
          {!!isTraded && (
            <Icon
              icon={<ArrowLeftRight />}
              size="8px"
              style={{ position: 'absolute', top: 0, left: 0 }}
            />
          )}
          <Typography variant="caption">
            <img
              height={height}
              src={getNHLTeamLogoUrl(nhlid)}
              alt={`${draftPick.originalTeam.abbreviation} R${draftPick.round}`}
              style={{ opacity: fadeLogo ? '50%' : '100%' }}
            />
          </Typography>
        </TooltipSpan>
      </Container>
    </Row>
  );
};
