import React, { useState } from 'react';
import styled from 'styled-components';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { rankToColor } from '../../helpers/tables';
import { getNHLTeamLogoUrl } from '../../helpers/logos';
import { TooltipSpan, Unprinted } from './report.elements';
import MiniTrendLine from './minitrendline';
import { applyAlphaMultToHSLAString, bruinsGold } from '../../helpers/plotting';
import { HoverInteractive } from '../bdd';
import { formatBddStat } from 'helpers/format';
import { EventVideoController } from 'components/bdd/Video';

const VerticalTable = styled.table`
  width: 100%;
  text-align: center;
  tr {
    border-bottom: 1px solid #dddddddd;
  }

  .subtitle {
    text-align: center;
    font-size: 0.8em;
    font-weight: normal;
    font-style: italic;
  }
`;
const TdRank = styled.td.attrs((props) => ({
  style: { background: props.color },
}))`
  font-size: 0.8em;
  width: 5%;
`;

const TdValue = styled.td((props) => ({
  background: `linear-gradient(to ${props.isRight ? 'left' : 'right'}, ${
    props.color
  }, #fff)`,
  width: '20%',
  '@media print': {
    background: applyAlphaMultToHSLAString(props.color, 0.6),
  },
}));

const TrStat = styled.tr`
  cursor: pointer;
  :hover {
    border-bottom: 1px solid ${bruinsGold};
  }
  ${(props) =>
    `${
      props.gray
        ? `color: #333;

        td {
            background: #ddd;
        };
        @media print {
            display: none;
        };
        `
        : null
    }`}
`;
const TdStat = ({ description, children, ...rest }) => {
  return (
    <td style={{ width: '50%', cursor: 'pointer' }} {...rest}>
      <TooltipSpan placement='top' show={300} content={description}>
        {children}
      </TooltipSpan>
    </td>
  );
};

export function VerticalStatTableV2({
  title,
  subtitle,
  teams,
  data,
  stats,
  format,
  rankings,
  // sectionKey,
  showTeams,
  adminMode,
  hiddenStats = [],
  dataByGame,
  numTeams = 31,
  highlightCallback,
  valueHoverCallback,
  rankHoverCallback,
  onStatClick,
  onStatDoubleClick,
  showInfoMessages,
  slugToMetastats,
  renderCallback,
}) {
  const [showAll, setShowAll] = useState(true);
  const [statModalInfo, setStatModalInfo] = useState({
    show: false,
    statSlug: null,
    data: null,
  });

  // If no data found for a team (e.g. in game PP tab), still want a reference to the team
  const idxToData = teams.reduce((acc, curr) => ({ 
    ...acc, 
    [curr.slug]: !!data.find(d => d.slug === curr.slug) ? data.find(d => d.slug === curr.slug) : {}
  }), {})

  const slugToInfo = {};
  const slugToDescription = {};
  const slugToPercentage = {};
  const slugToNegative = {};
  const slugToIsTimeStat = {};
  const slugToHasDecimals = {};
  const statSlugToLabel = {};
  format.stat_definitions.forEach((s) => {
    slugToInfo[s.slug] = s;
    slugToDescription[s.slug] = s.description;
    slugToPercentage[s.slug] = s.is_percentage;
    slugToNegative[s.slug] = s.negative;
    slugToHasDecimals[s.slug] = s.has_decimals;
    slugToIsTimeStat[s.slug] = s.is_time_stat;
    statSlugToLabel[s.slug] = s.label;
  });

  // getColor function used to determine color of cells
  // if a highlightCallback supplied it should accept an object with
  // statSlug, value, rank, numTeams
  // if rankings are supplied, this will be default coloring
  const getColor = highlightCallback
    ? highlightCallback
    : rankings
    ? rankToColor
    : () => null;

  const formatValue = (statSlug, val, teamSlug) => {
    if (!!renderCallback) {
      return renderCallback({ statSlug, val, statInfo: slugToInfo[statSlug], teamSlug, data: idxToData[teamSlug] })
    }
    if (isNaN(val) && typeof val === 'string' && val.length > 0) {
      return val;
    }
    return formatBddStat(val, slugToInfo[statSlug], { showPercent: true });
  };

  const showRankings = !!rankings;
  return (
    <>
      <VerticalTable>
        <thead>
          <tr>
            <th colSpan={showRankings ? 3 : 2}>
              {showTeams ? (
                <>
                  {!!teams[0].nhlid && (
                    <img
                      src={getNHLTeamLogoUrl(teams[0].nhlid)}
                      height="20px"
                    />
                  )}
                  {teams[0].longname}
                </>
              ) : null}
            </th>
            <th>
              {title}
              {subtitle && (
                <div className="subtitle">
                  <em>{subtitle}</em>
                </div>
              )}
            </th>
            <th colSpan={showRankings ? 3 : 2}>
              {showTeams ? (
                <>
                  {!!teams[1].nhlid && (
                    <img
                      src={getNHLTeamLogoUrl(teams[1].nhlid)}
                      height="20px"
                    />
                  )}
                  {teams[1].longname}
                </>
              ) : null}
            </th>
          </tr>
        </thead>
        <tbody>
          {stats.map((statSlug, i) => {
            const statLabel = statSlugToLabel[statSlug];
            const statUniqueId = slugToInfo[statSlug]?.unique_id;
            if (!adminMode) {
              if (hiddenStats.includes(statSlug) && !showAll) {
                return (
                  <tr style={{ display: 'none' }} key={`hidden-${i}`}></tr>
                );
              }
            }
            try {
              var rank1 = rankings[teams[0].slug][0].rankings[statSlug].ranking;
              var rank2 = rankings[teams[1].slug][0].rankings[statSlug].ranking;
              var pct1 =
                rankings[teams[0].slug][0].rankings[statSlug].percentile;
              var pct2 =
                rankings[teams[1].slug][0].rankings[statSlug].percentile;
            } catch {
              var rank1 = null;
              var rank2 = null;
            }
            const value1 = idxToData[teams[0].slug][statLabel];
            const value2 = idxToData[teams[1].slug][statLabel];
            const toi1 = idxToData[teams[0].slug]['toi']; // some stats need to do normalization (in game)
            const toi2 = idxToData[teams[0].slug]['toi'];
            const statDict1 = {
              statSlug,
              uniqueId: statUniqueId,
              negative: slugToNegative[statSlug],
              isCountingStat: slugToInfo[statSlug]?.counting_stat,
              description: slugToInfo[statSlug]?.description,
              rank: rank1,
              percentile: pct1,
              value: value1,
              numTeams: numTeams,
              label: statSlugToLabel[statSlug],
              toi: toi1,
            };
            const statDict2 = {
              statSlug,
              uniqueId: statUniqueId,
              negative: slugToNegative[statSlug],
              isCountingStat: slugToInfo[statSlug]?.counting_stat,
              description: slugToInfo[statSlug]?.description,
              rank: rank2,
              percentile: pct2,
              value: value2,
              numTeams: numTeams,
              label: statSlugToLabel[statSlug],
              toi: toi2,
            };
            const color1 = getColor(statDict1);
            const color2 = getColor(statDict2);
            const mean =
              !!slugToMetastats && statUniqueId in slugToMetastats
                ? slugToMetastats[statUniqueId].mean
                : null;
            const stddev =
              !!slugToMetastats && statUniqueId in slugToMetastats
                ? slugToMetastats[statUniqueId].stddev
                : null;

            return (
              <TrStat
                key={statSlug}
                gray={hiddenStats.includes(statSlug) && adminMode}
              >
                {showRankings && (
                  <TdRank color={color1}>
                    {!!rankHoverCallback
                      ? <HoverInteractive
                        placement='right'
                        renderContent={(forceClose) => rankHoverCallback(statDict1, teams[0].slug,forceClose)}
                      >
                        {rank1}
                      </HoverInteractive>
                      : rank1
                    }
                  </TdRank>
                )}
                <TdValue isRight={false} color={color1}>
                  <EventVideoController>
                    {valueHoverCallback ? (
                      <HoverInteractive
                        placement={'right'}
                        renderContent={(forceClose) =>
                          valueHoverCallback(
                            statDict1,
                            teams[0].slug,
                            forceClose,
                            idxToData[teams[0].slug]
                          )
                        }
                      >
                        <>{formatValue(statSlug, value1, teams[0].slug)}</>
                      </HoverInteractive>
                    ) : (
                      formatValue(statSlug, value1, teams[0].slug)
                    )}
                  </EventVideoController>
                </TdValue>
                <td>
                  {!!dataByGame && !!dataByGame[teams[0].slug][statLabel] ? (
                    <MiniTrendLine
                      type="bar"
                      xs={dataByGame[teams[0].slug].xs}
                      ys={dataByGame[teams[0].slug][statLabel]}
                      negative={slugToNegative[statSlug]}
                      avgLine={mean}
                      stddev={stddev}
                      n={8}
                    />
                  ) : null}
                </td>
                <TdStat
                  description={slugToDescription[statSlug]}
                  onClick={() => onStatClick(statSlug, statDict1, statDict2)}
                  onDoubleClick={() => {
                    onStatDoubleClick(statSlug, statDict1, statDict2);
                  }}
                >
                  {statLabel}
                </TdStat>

                <td>
                  {!!dataByGame && !!dataByGame[teams[1].slug][statLabel] ? (
                    <MiniTrendLine
                      type="bar"
                      xs={dataByGame[teams[1].slug].xs}
                      ys={dataByGame[teams[1].slug][statLabel]}
                      negative={slugToNegative[statSlug]}
                      avgLine={mean}
                      stddev={stddev}
                      n={8}
                    />
                  ) : null}
                </td>
                <TdValue isRight={true} color={color2}>
                  <EventVideoController>
                    {valueHoverCallback ? (
                      <HoverInteractive
                        placement={'left'}
                        renderContent={(forceClose) =>
                          valueHoverCallback(
                            statDict2,
                            teams[1].slug,
                            forceClose
                          )
                        }
                      >
                        <>{formatValue(statSlug, value2, teams[1].slug)}</>
                      </HoverInteractive>
                    ) : (
                      formatValue(statSlug, value2, teams[1].slug)
                    )}
                  </EventVideoController>
                </TdValue>
                {showRankings && <TdRank color={color2}>
                  {!!rankHoverCallback
                    ? <HoverInteractive
                      placement='left'
                      renderContent={(forceClose) => rankHoverCallback(statDict1, teams[1].slug, forceClose)}
                    >
                      {rank2}
                    </HoverInteractive>
                    : rank2
                  }
                </TdRank>}
              </TrStat>
            );
          })}
        </tbody>
      </VerticalTable>
      {showInfoMessages && (
        <Unprinted>
          <small>
            <em>Click a stat for more info</em>
          </small>
        </Unprinted>
      )}
    </>
  );
}
