import { useEffect, useState } from 'react';

const getPlaybackTimes = (playerState, videoRef) => {
  const { targetTime, targetDuration, frontPad, backPad } = playerState;

  // Keep track of start and end time -- either it's the full clip length OR its supplied as a target with offsets
  let startTime;
  let endTime;

  if (targetTime || targetDuration) {
    startTime = !!targetTime ? targetTime - frontPad : null;
    endTime = !!targetTime ? targetTime + targetDuration + backPad : null;
  } else {
    startTime = 0;
    endTime = videoRef.current?.duration;
  }

  const duration = endTime - startTime;

  return {
    startTime,
    endTime,
    duration,
  };
};

const applyTrim = (playerState, progress) =>
  playerState.leadTrim > progress
    ? playerState.leadTrim
    : playerState.trailTrim < progress
    ? playerState.trailTrim
    : progress;

export const useVideoClipPlayer = ({ videoRef, clip = {}, autoPlay = false }) => {
  const getReadyStates = () => {
    if (!videoRef || !videoRef.current) {
      return {
        isReady: false,
        isFullyReady: false,
        buffering: false,
      };
    }

    return {
      isReady: videoRef.current.readyState > 2,
      isFullyReady: videoRef.current.readyState === 4,
      buffering: videoRef.current.networkState === 2,
    };
  };

  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    videoRef.current.addEventListener('loadstart', handleReadyStateChange);
    videoRef.current.addEventListener('loadeddata', handleReadyStateChange);

    return () => {
      if (!videoRef || !videoRef.current) {
        return;
      }

      videoRef.current.removeEventListener('loadstart', handleReadyStateChange);
      videoRef.current.removeEventListener('loadeddata', handleReadyStateChange);
    };
  }, [videoRef]);

  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    setPlayerState((playerState) => ({ ...playerState, ...getReadyStates() }));
  }, [videoRef?.current?.networkState, videoRef?.current?.readyState]);

  const initialPlayerState = {
    ...clip,
    targetDuration: clip.eventDuration,
    targetTime: clip.eventTime,
    frontPad: clip.frontPad || 5,
    backPad: clip.backPad || 5,
    trailTrim: clip.trailTrim || 100,
    leadTrim: clip.leadTrim || 0,
    isPlaying: autoPlay,
    ...getReadyStates(),
  };

  const [playerState, setPlayerState] = useState(initialPlayerState);

  const togglePlay = () => {
    setPlayerState((playerState) => ({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    }));
  };

  const handleOnTimeUpdate = () => {
    const { startTime, duration } = getPlaybackTimes(playerState, videoRef);
    const currentTime = videoRef.current.currentTime;
    const progress = applyTrim(playerState, ((currentTime - startTime) / duration) * 100);

    const isFinished = progress >= Math.min(100, playerState.trailTrim);

    setPlayerState((playerState) => ({
      isPlaying: false,
      isReady: false, // ready to play at least a couple of frames
      isFullyReady: false, // ready state of 4 -- should have no buffering issues
      isInitialized: false, // Have we set the video start, autoplay, etc?
      speed: 1,
      ...playerState,
      progress,
      currentTime,
      isFinished,
    }));
  };

  const handleVideoProgress = (progress) => {
    const { startTime, duration } = getPlaybackTimes(playerState, videoRef);
    const trimmedProgress = applyTrim(playerState, progress);

    videoRef.current.currentTime = startTime + (duration / 100) * trimmedProgress;
    setPlayerState((playerState) => ({
      ...playerState,
      progress: trimmedProgress,
    }));
  };

  const seekToSeconds = (seconds) => {
    const { startTime, duration } = getPlaybackTimes(playerState, videoRef);

    videoRef.current.currentTime = seconds;
    setPlayerState((playerState) => ({
      ...playerState,
      progress: applyTrim(playerState, ((seconds - startTime) / duration) * 100),
    }));
  };

  const handleVideoSpeed = (speed) => {
    videoRef.current.playbackRate = speed;
    setPlayerState((playerState) => ({
      ...playerState,
      speed,
    }));
  };

  const toggleMute = () => {
    setPlayerState((playerState) => ({
      ...playerState,
      isMuted: !playerState.isMuted,
    }));
  };

  const handleReadyStateChange = () => {
    const readyStates = getReadyStates();
    setPlayerState((playerState) => ({
      ...playerState,
      ...readyStates,
    }));
  };

  const setFrontPad = (val) => {
    const pad = parseInt(val);
    const currentTime = videoRef.current.currentTime;
    const newDuration = playerState.backPad + pad;
    const startTime = playerState.targetTime - pad;
    const progress = applyTrim(
      playerState,
      ((currentTime - startTime) / newDuration) * 100
    );

    setPlayerState((playerState) => ({
      ...playerState,
      frontPad: pad,
      progress,
      currentTime,
    }));
  };

  const setBackPad = (val) => {
    const pad = parseInt(val);
    const currentTime = videoRef.current.currentTime;
    const newDuration = playerState.frontPad + pad;
    const startTime = playerState.targetTime - playerState.frontPad;
    const progress = applyTrim(
      playerState,
      ((currentTime - startTime) / newDuration) * 100
    );

    setPlayerState((playerState) => ({
      ...playerState,
      backPad: pad,
      progress,
      currentTime,
    }));
  };

  const handleVideoTrim = (trim) => {
    setPlayerState((playerState) => ({
      ...playerState,
      leadTrim: trim.lead,
      trailTrim: trim.trail,
    }));
  };

  const isClipEdited = () => {
    const keysToCompare = ['frontPad', 'backPad', 'leadTrim', 'trailTrim'];

    return keysToCompare.find((key) => playerState[key] != initialPlayerState[key]);
  };

  const resetClip = () => setPlayerState(initialPlayerState);

  // Getting rid of error where we trigger the video play twice
  const [playTriggered, setPlayTriggered] = useState(false);
  const handlePlay = () => {
    if (!playTriggered && videoRef.current.paused) {
      setPlayTriggered(true);
      videoRef.current
        .play()
        .then(() => {
          setPlayTriggered(false);
        })
        .catch(() => {
          setPlayTriggered(false);
          videoRef.current.pause();
          videoRef.current.play();
        });
    }
  };

  // On "isPlaying" change
  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    playerState.isPlaying ? handlePlay() : videoRef.current.pause();
  }, [playerState.isPlaying, videoRef]);

  // On isMuted change
  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    playerState.isMuted
      ? (videoRef.current.muted = true)
      : (videoRef.current.muted = false);
  }, [playerState.isMuted, videoRef]);

  // On change of clip Id, reset playerState
  // Any player-wide state changes like muting should still be applied
  useEffect(() => {
    if (!clip.id) return;
    if (playerState.isPlaying) {
      handlePlay();
    }

    const { startTime, duration } = getPlaybackTimes(initialPlayerState, videoRef);
    const trimmedProgress = applyTrim(initialPlayerState, 0);

    videoRef.current.currentTime = isNaN(duration)
      ? 0
      : startTime + (duration / 100) * trimmedProgress;

    setPlayerState({
      ...initialPlayerState,
      progress: trimmedProgress,
      isMuted: playerState.isMuted,
      isPlaying: playerState.isPlaying,
    });
  }, [clip]);

  return {
    playerState,
    ...getPlaybackTimes(playerState, videoRef),
    isClipEdited,
    resetClip,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    handleVideoTrim,
    toggleMute,
    seekToSeconds,
    setFrontPad,
    setBackPad,
    autoPlay,
  };
};
