import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column, Container, Row } from 'components/bdd/Layout';
import { ModalContext, useModal } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import { SectionHeader, TooltipSpan } from 'components/reports';
import { roundToX, strContains } from 'helpers/data';
import {
  getNextOrCurrentSeason,
  seasonToEndYear,
  seasonToStartYear,
} from 'helpers/hockeyutils';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { IRIcon, LTIRIcon, WaiverExemptIcon } from './components/BFBadges';
import BFContractBubble from './components/BFContractBubble';
import BFPlayerBreakdown from './components/BFPlayerBreakdown';
import { formatDollars } from 'helpers/contracts';
import { capitalize } from 'helpers/string';
import Icon from 'components/bdd/Icon';
import { theme } from 'constants';
import { Asterisk } from 'react-bootstrap-icons';
import { BFPlayerHeader } from './BFPlayerHeader';

/* This entry point is useful if you have only a player slug */
export function BFPlayerContractsWrapper({ bddPlayerSlug }) {
  // TODO this
}

export const BFContractTooltip = ({ bfPlayer, bfCapHit }) => {
  return (
    <Column gap={2} style={{ textAlign: 'center' }}>
      <Row gap={4}>
        <Typography variant="body1">AAV:</Typography>
        <Typography variant="body2">
          {formatDollars(bfCapHit.currentAav)} (NHL Active)
        </Typography>
      </Row>

      {!!bfCapHit.retainedPct > 0 && (
        <Container>
          <Row gap={4}>
            <Typography variant="body1">Retention:</Typography>
            <Typography variant="body2">{bfCapHit.retainedPct}%</Typography>
          </Row>
          {!!bfCapHit.retainedDetails &&
            JSON.parse(bfCapHit.retainedDetails)?.map((d) => (
              <Row gap={4}>
                <Typography variant="caption">
                  {d.team_slug.split(' ').map(capitalize).join(' ')}
                </Typography>
                <Typography variant="caption">{formatDollars(d.cap_hit)}</Typography>
                <Typography variant="caption">({roundToX(d.percent, 1)}%)</Typography>
              </Row>
            ))}
        </Container>
      )}

      {!!bfPlayer && (
        <Container>
          {bfPlayer.waiverExempt && (
            <Row gap={4}>
              <WaiverExemptIcon showTooltip={false} /> <div>Waiver exempt</div>
            </Row>
          )}
          {bfPlayer.status === 'LongTermInjuredReserve' && (
            <Row gap={4}>
              <LTIRIcon showTooltip={false} /> <div>Player is on LTIR.</div>
            </Row>
          )}
          {bfPlayer.status === 'InjuredReserve' && (
            <Row gap={4}>
              <IRIcon showTooltip={false} /> <div>Player is on IR.</div>
            </Row>
          )}
        </Container>
      )}

      <div>
        <em>Click for more info</em>
      </div>
    </Column>
  );
};

export function BFPlayerContracts({ bfPlayer, bfCapHit, playerName }) {
  const aavStr = formatDollars(bfCapHit?.currentAav);
  const capHitStr = formatDollars(bfCapHit?.finalCapHit);
  const futureAavStr = formatDollars(bfCapHit?.futureAav);

  const { renderModal, hideModal } = useContext(ModalContext);

  const handleShowModal = () => {
    renderModal({
      size: 'xl',
      title: <BFPlayerHeader bfPlayer={bfPlayer} />,
      body: (
        <Container padding={2}>
          <BFPlayerBreakdown bfPlayerSlug={bfPlayer.slug} />
        </Container>
      ),
      footer: (
        <Button variant="secondary" onClick={() => hideModal()}>
          Close
        </Button>
      ),
    });
  };

  // No contracts ever
  if (
    !bfCapHit ||
    (!bfCapHit.hasPastContract &&
      !bfCapHit.hasActiveContract &&
      !bfCapHit.hasFutureContract)
  ) {
    return <Typography variant="caption">No contract</Typography>;
  }

  // Only past contracts
  if (!bfCapHit.hasActiveContract && !bfCapHit.hasFutureContract) {
    return (
      <Container>
        <BFContractBubble onClick={() => handleShowModal()}>
          <Row columnGap={1}>
            <Row gap={4}>
              <Typography>{bfCapHit.expiryStatus}</Typography>
            </Row>
          </Row>
        </BFContractBubble>
      </Container>
    );
  }

  // Only future contract
  if (!bfCapHit.hasActiveContract && bfCapHit.hasFutureContract) {
    return (
      <Container>
        <BFContractBubble onClick={() => handleShowModal()}>
          <Row columnGap={1}>
            <Row gap={4}>
              <Typography>Future</Typography>
            </Row>
          </Row>
        </BFContractBubble>

        <Typography variant="caption" style={{ fontSize: '7pt' }}>
          {!!bfCapHit.hasFutureContract ? (
            <>
              Signed: {futureAavStr} x{bfCapHit.futureRemainingTerm}
            </>
          ) : (
            <>
              Expiry: {bfCapHit.expiryStatus}{' '}
              {!!strContains(bfCapHit.expiryStatusDetails, 'Arbitration Eligible') && (
                <FontAwesomeIcon icon={faGavel} />
              )}
            </>
          )}
        </Typography>
      </Container>
    );
  }

  // Has a current contract
  return (
    <Container>
      <TooltipSpan
        content={<BFContractTooltip bfPlayer={bfPlayer} bfCapHit={bfCapHit} />}
      >
        <BFContractBubble onClick={() => handleShowModal()}>
          {!!bfCapHit && (
            <Row columnGap={1}>
              <Column>
                {bfPlayer.waiverExempt && (
                  <WaiverExemptIcon showTooltip={false} size="10px" />
                )}
                {bfPlayer.status === 'LongTermInjuredReserve' && (
                  <LTIRIcon showTooltip={false} size="10px" />
                )}
                {bfPlayer.status === 'InjuredReserve' && (
                  <IRIcon showTooltip={false} size="10px" />
                )}
              </Column>
              <Row gap={2}>
                <Row gap={4}>
                  <Typography>{capHitStr}</Typography>
                  <Typography>x{bfCapHit.currentRemainingTerm}</Typography>
                </Row>
                {bfCapHit.retainedPct > 0 && (
                  <Icon
                    color={theme.colors.categorical.red}
                    icon={<Asterisk />}
                    size={10}
                  />
                )}
              </Row>
            </Row>
          )}
        </BFContractBubble>
      </TooltipSpan>

      <Typography variant="caption" style={{ fontSize: '7pt' }}>
        {!!bfCapHit.hasFutureContract ? (
          <>
            Re-signed: {futureAavStr} x{bfCapHit.futureRemainingTerm}
          </>
        ) : (
          <>
            Expiry: {bfCapHit.expiryStatus}{' '}
            {!!strContains(bfCapHit.expiryStatusDetails, 'Arbitration Eligible') && (
              <FontAwesomeIcon icon={faGavel} />
            )}
          </>
        )}
      </Typography>
    </Container>
  );
}
