import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { getRedGreenColor, stddevColor } from '../../helpers/tables';
import { theme } from 'constants';
import { formatBddStat } from 'helpers/format';
import { getMetastatsBySlug, searchMetastatsSplitsForStat } from 'helpers/stats';
import { roundToX } from 'helpers/data';

export const SLStatline = ({
  data,
  row,
  indexLabel,
  excludeStatLabels,
  searchMetastatsOptions,
}) => {
  if (!row)
    return (
      <Typography textAlign="center" variant="stat">
        No stats available
      </Typography>
    );
  const statDefinitions = data.format.stat_definitions;

  return (
    <Container width="100%">
      <Row alignItems="end" justifyContent="center">
        {indexLabel && <Container minWidth={150}>{indexLabel}</Container>}
        {!row ? (
          <Typography textAlign="center" variant="stat">
            No stats available
          </Typography>
        ) : (
          <Row columnGap={2}>
            {statDefinitions.map((sd) => (
              <Statbox
                key={sd.slug}
                statDefinition={sd}
                row={row}
                metastats={data.extras.metastats}
                excludeStatLabels={excludeStatLabels}
                searchMetastatsOptions={searchMetastatsOptions}
              />
            ))}
          </Row>
        )}
      </Row>
    </Container>
  );
};

export const Statbox = ({
  statDefinition,
  statValue,
  row,
  metastats,
  excludeStatLabels,
  searchMetastatsOptions,
}) => {
  if (!row && statValue == undefined) return null;
  let value = statValue != undefined ? statValue : row[statDefinition.label];

  const metas = searchMetastatsOptions
    ? searchMetastatsSplitsForStat(
        metastats,
        statDefinition,
        row,
        searchMetastatsOptions
      )?.[1]
    : metastats.league &&
      getMetastatsBySlug(metastats.league[0].metastats, statDefinition.slug);

  if (!metas) console.log(statDefinition.slug);

  return (
    <Container width={theme.spacing[11]} variant="button">
      <Column columnGap={1}>
        {!excludeStatLabels && (
          <Typography variant="stat" textAlign="center" noWrap>
            {statDefinition.label}
          </Typography>
        )}
        <Border>
          <Container
            style={{
              backgroundColor:
                !metas || statDefinition.is_time_stat
                  ? 'white'
                  : stddevColor(value, metas, statDefinition.negative),
            }}
          >
            <Typography variant="stat" textAlign="center">
              {formatBddStat(value, statDefinition)} {!metas && '*'}
              {/* {formatBddStat(value, statDefinition)} /{' '}
                {roundToX(metas.mean, 2)} / {roundToX(metas.max, 2)} /{' '}
                {roundToX(metas.min, 2)} */}
            </Typography>
          </Container>
        </Border>
      </Column>
    </Container>
  );
};
