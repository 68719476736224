import React, { useState, useEffect, useRef } from 'react';
// import Plot from 'react-plotly.js';
import { useResizeDetector } from 'react-resize-detector';

// If we wanted to use a minified, cartesian-only build of plotly (and save 15 MB in bundle size)
// Unfortunately doesn't work with scatterpolar charts
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js-cartesian-dist-min'
const Plot = createPlotlyComponent(Plotly);

export default function BDDPlotly({
    data,
    layout={},
    config={},
    frames,
    displayModeBar=true,
    style={},
    onClick,
    onSelected,
    onDoubleClick,
    divStyle,
    fetching,
    error,
    useResizeHandler=true,
    onInitialized,
    onUpdate,

    // for plots that are rendered into animating spaces.  Overwrites layout.width
    // Ideally you don't need this, but resizehandler won't detect changes due to animation
    trackResizeOnRender=false 
}) {
    if (trackResizeOnRender) {
        var { width, ref } = useResizeDetector()
        layout.width = width
    } else {
        var ref = null
    }
    
    if (!displayModeBar) {
        config.displayModeBar = false
    }
    return (
        <div style={divStyle} ref={ref}>
            <Plot
                data={data}
                layout={{
                    font: {
                        family: 'Arial'
                    },
                    ...layout
                }}
                frames={frames}
                config={config}
                useResizeHandler={useResizeHandler}
                style={{width: "100%", height: "100%", ...style}}
                onClick={onClick}
                onInitialized={onInitialized}
                onSelected={onSelected}
                onDoubleClick={onDoubleClick}
                onUpdate={onUpdate}
            />
        </div>
    )
}
