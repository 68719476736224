import { theme } from 'constants/theme';
import React, { useEffect, useState } from 'react';
import { default as BaseModal } from 'react-overlays/Modal';
import styled from 'styled-components';

const ModalDialog = styled.div({
  // ...theme.borders.thin,
  // ...theme.borders.roundedMedium,
  position: 'fixed',
  zIndex: 1050,
  top: 0,
  left: 0,
  right: 0,
  transition: 'all 0.3s ease-in-out',
});

const mdDialogStyles = {
  width: '60%',
  maxHeight: '90vh',
  overflow: 'auto',
  marginTop: theme.spacing[4],
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: theme.colors.light.background,
};

const lgDialogStyles = {
  width: '70%',
  maxHeight: '90vh',
  overflow: 'auto',
  marginTop: theme.spacing[4],
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: theme.colors.light.background,
};

const xlDialogStyles = {
  width: '80%',
  height: '90vh',
  marginTop: theme.spacing[4],
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: theme.colors.light.background,
};

const xxlDialogStyles = {
  width: '90vw',
  height: '90vh',
  marginTop: theme.spacing[4],
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: theme.colors.light.background,
};

const fullscreenStyles = {
  backgroundColor: theme.colors.light.background,
  width: '100vw',
  height: '100vh',
};

const Backdrop = styled.div({
  position: 'fixed',
  zIndex: 200,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'black',
  opacity: 0.5,
});

const sizeToComponent = {
  md: mdDialogStyles,
  lg: lgDialogStyles,
  xl: xlDialogStyles,
  xxl: xxlDialogStyles,
  fullscreen: fullscreenStyles,
};

export const Modal = React.forwardRef(
  ({ size = 'lg', show, onHide, modalProps, children }, ref) => {
    const [opacity, setOpacity] = useState(0);
    const renderBackdrop = (props) => <Backdrop {...props} />;
    const renderDialog = (props) => (
      <ModalDialog
        ref={ref}
        {...modalProps}
        style={{
          ...sizeToComponent[size],
          opacity,
        }}
      >
        {children}
      </ModalDialog>
    );

    useEffect(() => setOpacity(1), []);

    return (
      <BaseModal
        show={show}
        onHide={onHide}
        renderBackdrop={renderBackdrop}
        renderDialog={renderDialog}
      />
    );
  }
);
