import { Border, Column, Container, Row } from 'components/bdd/Layout';
import BDDStatTableV2 from 'components/bdd/Stats/BDDStatTableV2';
import { Typography } from 'components/bdd/Typography';
import { EventVideoController } from 'components/bdd/Video';
import { cellHoverCallback, highlightCallback } from './helpers';

export const HeadlineStatTable = ({
  bddPlayer,
  seasonStatContext,
  recentGamesStatContext,
}) => {
  const { stats: seasonStats, data: seasonStatData, filters } = seasonStatContext;
  const { stats: recentStats, data: recentStatData } = recentGamesStatContext;

  if (!seasonStats || !recentStats) {
    return <Typography variant="body2">Loading...</Typography>;
  }

  const labelColumn = {
    id: 'header',
    Cell: ({
      cell: {
        row: { original: d },
      },
    }) => {
      return (
        <Container paddingRight={3}>
          <Typography variant="stat" noWrap>
            <b>
              <Row columnGap={1} justifyContent="space-between">
                {d.label} {d.league_slug}
              </Row>
            </b>
          </Typography>
        </Container>
      );
    },
  };

  let rows = [];

  if (!!recentStats) {
    rows = rows.concat(
      recentStats
        .filter(
          (s) =>
            s.slug == bddPlayer.slug &&
            !['nhl_postseason', 'nhl_preseason'].includes(s.game_type)
        )
        .map((s) => ({
          id: 'recent',
          label: `Recent Games`,
          statContext: recentGamesStatContext,
          ...s,
        }))
    );
  }

  if (!!seasonStats) {
    rows = rows.concat(
      seasonStats
        .filter(
          (s) =>
            s.slug == bddPlayer.slug &&
            !['nhl_postseason', 'nhl_preseason'].includes(s.game_type)
        )
        .map((s) => ({
          id: 'season',
          label: `Season to Date`,
          statContext: seasonStatContext,
          ...s,
        }))
    );
  }

  const postseasonStats = seasonStats?.find(
    (s) => s.slug == bddPlayer.slug && s.game_type.includes('nhl_postseason')
  );

  if (!!postseasonStats) {
    rows.push({
      id: 'playoffs',
      label: 'Playoffs to Date',
      statContext: seasonStatContext,
      ...postseasonStats,
    });
  }

  return (
    <Container overflow="auto">
      <Typography variant="stat">
        <EventVideoController>
          <BDDStatTableV2
            leaveSpaceForSort
            data={rows}
            indexColumns={[labelColumn]}
            format={seasonStatData.format}
            highlight
            highlightCallback={highlightCallback}
            cellHoverCallback={cellHoverCallback}
            statSlugs={seasonStatData.format?.stat_definitions
              .filter((sd) => sd.slug != 'toi' && sd.slug != 'pbr')
              .map((sd) => sd.slug)}
          />
        </EventVideoController>
      </Typography>
    </Container>
  );
};
