import { strContains } from "./data"

export function positionToFDG(pos) {
    /*
    takes position in some alternative format and returns F, D or G
    */
    pos = pos.toLowerCase()
    // First check for full substring matches
    if (pos.includes('center') || pos.includes('wing') || pos.includes('forward')){
      return 'f'
    } else if (pos.includes('defence')) {
      return 'd'
    } else if (pos.includes('goal')) {
      return 'g' 
    }

    // Now check substrings with specific position
    if (['c', 'lw', 'rw'].indexOf(pos) >= 0 || strContains(pos, ['lw', 'rw', 'c'], true, 'any')) {
        return 'f'
    } else if (['rd', 'ld'].indexOf(pos) >= 0 || strContains(pos, ['ld', 'rd'], true, 'any')) {
        return 'd'
    } else if (['g'].indexOf(pos) >= 0) {
        return 'g'
    }

    // Finally check least specific options
    if (['f'].indexOf(pos) >= 0 || strContains(pos, ['f'], true, 'any')) {
        return 'f'
    } else if (['d'].indexOf(pos) >= 0 || strContains(pos, ['d'], true, 'any')) {
        return 'd'
    }
    
    return null
}

export const posFDGToPosition = {
    'f': 'forwards',
    'd': 'defensemen',
    'g': 'goalies',
}

const longToShortPosition = {
    'goalie': 'G',
    'left defence': 'LD',
    'right defence': 'RD',
    'left wing': 'LW',
    'center': 'C',
    'right wing': 'RW',
    'leftwing': 'LW',
    'rightwing': 'RW',
    'leftdefence': 'LD',
    'rightdefence': 'RD'
}
export function positionToAbbrev(pos) {
    /*
    takes position in long written form and return 'G', 'LD', 'RD', 'LW', 'C', 'RW'
    */
    if (!pos) return null
    pos = pos.toLowerCase()
    if (pos in longToShortPosition) {
        return longToShortPosition[pos]
    }
    return null
}
const shortToLongPosition = {
  'G': 'goalie',
  'LD': 'leftdefence',
  'RD': 'rightdefence',
  'LW': 'leftwing',
  'C': 'center',
  'RW': 'rightwing'
}

export const rinknetToDetailedPositions = (pos) => {
    /* takes position in some alternative format and returns [leftwing], [leftwing, center]... */
    const detailedPositions = Object.values(shortToLongPosition);
    pos = pos.toLowerCase()
    return pos.split('/').flatMap(p => {
      p = p.replace('r.', 'right').replace('l.', 'left').replace(' ', '');
      if (p === 'forward') {
        return ['leftwing', 'center', 'rightwing'];
      }
      if (p === 'defence') {
        return ['leftdefence', 'rightdefence'];
      }
      return detailedPositions.includes(p) ? p : null
    }).filter(p => !!p)
}


export function getStartOfOffseason() {
  const today = new Date();
  return new Date(today.getFullYear(), 5, 25) // June 25th (months are zero indexed) same as bs.get_start_of_offseason
}
export function getEndOfOffseason() {
  const today = new Date();
  return new Date(today.getFullYear(), 9, 9) // Oct 9 (months are zero indexed) same as bs.get_end_of_offseason
}
export function isOffseason() {
  const today = new Date();
  return getStartOfOffseason() < today && today < getEndOfOffseason();

}

export function getCurrentSeason() {
    // Return the current season, flipping over in start of year (used to be mid september)
    const cutoffMonth = 9 // oct 
    const cutoffDate = 9 // 9st
    const today = new Date()
    if ((today.getMonth() > cutoffMonth) ||
    (today.getMonth() === cutoffMonth && today.getDate() >= cutoffDate)) {
        return parseInt(`${today.getFullYear()}${today.getFullYear()+1}`)
        // because of COVID, we're more than a year off
        // return parseInt(`${today.getFullYear()-1}${today.getFullYear()}`)
    } else {
        return parseInt(`${today.getFullYear()-1}${today.getFullYear()}`)
        // return parseInt(`${today.getFullYear()-2}${today.getFullYear()-1}`)
    }
}

export function getCurrentDraftYear() {
    // Return the current season, flipping over in start of year (used to be mid september)
    const cutoffMonth = 6; 
    const cutoffDate = 30;
    const today = new Date();
    if ((today.getMonth() > cutoffMonth) ||
    (today.getMonth() === cutoffMonth && today.getDate() >= cutoffDate)) {
        return today.getFullYear()+1
    } else {
        return today.getFullYear()
    }
}

export const dateToSeason = (date, lookForward=false) => {
  // Given a date, return a season (8 char int)
  // "lookForward" - if true, will return next season after may
  date = new Date(date);
  const cutoffMonth = lookForward
    ? 4 // if we're "looking forward" we flip over in may
    : 9;  // otherwise, october
  if (date.getMonth() > cutoffMonth) {
    return parseInt(`${date.getFullYear()}${date.getFullYear()+1}`)
  } else {
    return parseInt(`${date.getFullYear()-1}${date.getFullYear()}`)
  }
}

export function getNextOrCurrentSeason() {
  // Returns current season in season, next season in offseason
  // Contracts flip over after end of season. Stats take till start of next season
  if (isOffseason()) {
    return getSeasonPlusMinusYears(getCurrentSeason(), 1);
  }
  return getCurrentSeason();
}

export function seasonToStartYear(season) {
  return parseInt(`${season}`.slice(0, 4))
}

export function seasonToEndYear(season) {
  return parseInt(`${season}`.slice(4))
}

export function season8to4(season, dash=false, seasonHasDash=false) {
    if (!season) return null;
    const seasonString = season.toString();
    const startSeason = seasonString.slice(2,4);
    const endseason = seasonHasDash ? seasonString.slice(7,9) : seasonString.slice(6,8)

    return `${startSeason}${dash ? '-' : ''}${endseason}`
}

export function multiSeason8to4(seasons, dash=false, seasonHasDash=false, split=',') {
    return seasons.split(split).map(xi => season8to4(xi.trim(), dash, seasonHasDash)).join(', ')
}

export function getPreviousSeason(season) {
    return parseInt(`${parseInt(season.toString().slice(0,4))-1}${parseInt(season.toString().slice(4))-1}`)
}
export function getSeasonPlusMinusYears(season, years=1) {
    // given a start season add or substract delta number seasons
    return parseInt(`${parseInt(season.toString().slice(0,4))+years}${parseInt(season.toString().slice(4))+years}`)
}

export function year4to2(year, apostrophe=true) {
  return `${apostrophe ? "'" : ''}${year.toString().slice(2,4)}`
}

export function nameToLastName(name) {
    if (!name) return name
    try {
        return strContains(name, ' ') ? name.split(' ').slice(1).join(' ').replace('"', '') : name;
    } catch {
        return name
    }
}

export function nameToInitials(name) {
  if (!name) return name
  try {
    return name.split(' ').map(w => w.slice(0,1).toUpperCase()).join('')
  } catch {
    return name.slice(0, 2).toUpperCase()
  }
}

export const heightToNum = h => {
    if (!h) return 0
    const [ft, inches] = h.split("'")
    if (inches) return parseFloat(ft) + parseFloat(inches)/12
    return parseFloat(ft)
}
// Height in feet as a float (6.083333) to string
export const heightToStr = (h, roundTo=1) => {
    const ft = Math.floor(h)
    const r = h - ft
    const inches = r * 12
    return `${ft.toFixed(0)}' ${parseFloat(inches.toFixed(roundTo))}"`
}
// Height in inches as a float (72.083333) to string
export const heightInchesToStr = (h, roundTo=1) => {
    const ft = parseInt(h / 12)
    const inches = h % 12
    return `${ft.toFixed(0)}' ${parseFloat(inches.toFixed(roundTo))}"`
}

export const longToShortLeague = league => {
    if (!league) return null
    return league.split(" ").map(xi => xi.slice(0,1)).join('')
}

export const isTargetGroup = targ => {
    return ['lines', 'pairs', 'units', 'linesbyplayer', 'pairsbyplayer', 'unitsbyplayer'].includes(targ)
}

export const standingsQueryToDivisions = data => {
    if (!data) return null
    if (!data.nhlStandings) return null

    return data.nhlStandings.team_stats.reduce((acc, curr) => {
      if (!(curr.divisionName in acc)) {
        acc[curr.divisionName] = [];
      }
      acc[curr.divisionName].push(curr.teamId)
      return acc;
    }, {});
}

export const filterSetToString = ({ manpowerSituation, strength }) => {
  if (strength && strength.toString().length > 1) {
    const d1 = strength.toString()[0]
    const d2 = strength.toString()[1]
    return `${d1}v${d2}`
  }
  return manpowerSituation
}