import PlayerHover from 'components/PlayersV2/Hover/playerhover';
import BDDDepthChart from 'components/ProScouting/bdddepthchart';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { ScenarioPlayerWarning } from './ScenarioPlayer';
import { Divider } from 'components/bdd/Divider';
import Draggable from 'components/bdd/draggable';
import { itemTypes } from 'constants';
import { ContextMenuWrapper } from 'components/bdd/ContextMenu/ContextMenuWrapper';
import { useDragLayer } from 'react-dnd';
import { useState } from 'react';
import { IconButton, LinkButton } from 'components/bdd/Button';
import BFPlayerContractsSmall from 'components/PlayersV2/Contract/BFPlayerContractsSmall';
import { formatDollars } from 'helpers/contracts';
import Icon from 'components/bdd/Icon';
import { ExclamationTriangle, Pen } from 'react-bootstrap-icons';
import BDDTag from 'components/PlayerTagging/bddtag';
import { GET_SCENARIO } from 'apollo/queries/proobjective.queries';
import { theme } from 'constants';
import { scenarioPlayerToWarning } from './helpers';
import { HoverInfo } from 'components/bdd';

export const ScenarioDepth = ({ scenario, size='sm', canEdit, onUpdate }) => {
  const [showRemoved, setShowRemoved] = useState({
    show: scenario.hasEmptyDepthChart,
    AHL: false,
    Prospect: false,
  });
  const [showAdded, setShowAdded] = useState(true);

  const refetchWrapperQueries = [{
    query: GET_SCENARIO,
    variables: {
      id: scenario.id,
      cacheOnly: false,
      includeCapSheet: true,
      includeChecks: true,
      includeTeamStrength: true,
      skipCache: true,
    }
  }]

  const slugToSP = scenario.playersIn
    ?.concat(scenario.freeAgents)
    ?.concat(scenario.playersOut)
    .reduce((acc, curr) => {
      acc[curr.slug] = curr;
      return acc;
    }, {});
  const slugToCapSheetPlayer = scenario.capSheet
    ?.players?.reduce((acc, curr) => {
      if (!curr.bddPlayer) return acc;
      acc[curr.bddPlayer.slug] = curr;
      return acc;
    }, {});

  const { isDragging: isAnyDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));

  const newPlayers = scenario.playersIn?.filter((p) => !p.isInDc);
  const oldPlayers = scenario.playersOut?.filter((p) => !p.isInDc);
  const oldPlayersByLeague = !!oldPlayers
    ? oldPlayers?.reduce(
        (acc, p) => {
          const dl = p.depthChartSpot?.depthLeague;
          if (!!dl) acc[dl].push(p);
          return acc;
        },
        { NHL: [], AHL: [], Prospect: [] }
      )
    : { NHL: [], AHL: [], Prospect: [] };

  const renderPlayer = ({
    lineupSpot,
    playerSlug,
    bddPlayer,
    isDragging,
    handleRemovePlayer,
    ...rest
  }) => {
    if (!bddPlayer) return <Typography variant="body2">{playerSlug}</Typography>;
    const sp = slugToSP?.[bddPlayer.slug];
    const csp = slugToCapSheetPlayer?.[bddPlayer.slug];
    const managedTags = bddPlayer.bddManagedTags;
    const roleTag = managedTags?.find((t) => t.tag.tagType === 'ROLE');
    const nonRoleTags = managedTags?.filter((t) => t.tag.tagType !== 'ROLE');
    return (
      <Container>
        <ContextMenuWrapper
          options={[
            {
              label: 'Remove player',
              onClick: () => handleRemovePlayer(lineupSpot, playerSlug),
            },
          ]}
        >
          <Row justifyContent="center" gap={8}>
            <PlayerHover
              playerSlug={bddPlayer.slug}
              disabled={isAnyDragging}
              delay={{ show: 1000, hide: 50 }}
            >
              <Typography variant={!!sp ? 'body1' : 'body2'}>
                {bddPlayer.firstname} {bddPlayer.lastname}
              </Typography>
            </PlayerHover>
            {!!sp && <ScenarioPlayerWarning scenarioPlayer={sp} canEdit={canEdit} />}
          </Row>
          {size === 'lg' && (
            <Row justifyContent='center' gap={8}>
              <Container>
                {csp?.source === 'scenario'
                  ? (
                    <Row gap={2}>
                      <Typography variant='body2'>
                        {formatDollars(csp.capHit)} x{csp.remainingTerm}
                      </Typography>
                      <Icon icon={<Pen/>} size='8px'/>
                    </Row>
                  ) : <BFPlayerContractsSmall 
                    name={`${bddPlayer.firstname} ${bddPlayer.lastname}`} 
                    bfPlayerSlug={bddPlayer.bfSlug} 
                    bfCapHit={bddPlayer.bfCapHit}
                  />
                }
              </Container>

              {!!roleTag && <Typography variant="body2">|</Typography>}
              {roleTag && (
                <BDDTag isProjection={roleTag.isProjection} tag={roleTag.tag} />
              )}
            </Row>
          )}
        </ContextMenuWrapper>
      </Container>
    );
  };

  const handleDCCacheUpdate = (cache, bddDepthChart, externalEffects) => {
    // Since our scenarios fetch their depthCharts in a nested query
    // We need custom logic injected into the useMutation hook of the bddDepthChart
    // In order to refresh the cache for our scenario
    // cache.modify({
    //   fields: {
    //     proObjectiveScenarios(existing = [], { readField }) {
    //       const currScenarioRef = existing.find((e) => readField('id', e) == scenario.id);
    //       if (!!currScenarioRef) {
    //         return existing;
    //         return existing
    //           .filter((e) => readField('id', e) != scenario.id)
    //           .concat([
    //             {
    //               ...scenario,
    //               depthChart: bddDepthChart,
    //             },
    //           ]);
    //       }
    //       return existing;
    //     },
    //   },
    // });

    onUpdate && onUpdate();
  };

  const renderDraggablePlayer = (p) => {
    return (
      <Draggable
        key={p.slug}
        itemType={itemTypes.DEPTH_CHART_PLAYER}
        data={{ playerSlug: p.bddPlayer.slug, ...p }}
        draggable={canEdit}
      >
        <Border>
          <Container paddingLeft={4} paddingRight={4}>
            {renderPlayer({ bddPlayer: p.bddPlayer })}
          </Container>
        </Border>
      </Draggable>
    );
  };

  const warnings = oldPlayers.map((p) => scenarioPlayerToWarning(p)).filter((w) => !!w);

  return (
    <Container>
      <Column gap={2}>
        {!!oldPlayers?.length && (
          <Container>
            <Row gap={8}>
              <Typography variant="body1">({oldPlayers.length}) Players Bank</Typography>

              {!!oldPlayersByLeague['NHL'].length && <LinkButton
                onClick={() =>
                  setShowRemoved({ ...showRemoved, show: !showRemoved.show })
                }
              >
                <Typography variant="body2">
                  {showRemoved.show ? 'Hide' : 'Show'}
                </Typography>
              </LinkButton>}

              {!!warnings.length && (
                <HoverInfo content={(
                  <Column gap={2}>
                    {warnings[0]}
                    <Typography variant='stat'>And {warnings.length - 1} more warnings...</Typography>
                  </Column>
                )}>
                    <IconButton color="red" icon={<ExclamationTriangle/>} onClick={() => setShowRemoved({ ...showRemoved, show: !showRemoved.show })}/>
                </HoverInfo>
              )}
            </Row>
            {showRemoved.show && (
              <Container padding={1} paddingLeft={4}>
                <Typography variant="body1" color={theme.colors.light.text.secondary}>
                  {oldPlayersByLeague['NHL'].length} NHL players
                </Typography>
                <Row gap={2} flexWrap>
                  {oldPlayersByLeague['NHL']?.map((p) => renderDraggablePlayer(p))}
                  {!oldPlayersByLeague['NHL'].length && (
                    <Typography variant="body2">None</Typography>
                  )}
                </Row>

                <Row gap={8}>
                  <Typography variant="body1" color={theme.colors.light.text.secondary}>
                    {oldPlayersByLeague['AHL'].length} AHL players
                  </Typography>
                  {!!oldPlayersByLeague['AHL'].length && <LinkButton
                    onClick={() =>
                      setShowRemoved({ ...showRemoved, AHL: !showRemoved.AHL })
                    }
                  >
                    <Typography variant="body2">
                      {showRemoved.AHL ? 'Hide' : 'Show'}
                    </Typography>
                  </LinkButton>}
                </Row>
                {showRemoved.AHL && (
                  <Row gap={2} flexWrap>
                    {oldPlayersByLeague['AHL']?.map((p) => renderDraggablePlayer(p))}
                    {!oldPlayersByLeague['AHL'].length && (
                      <Typography variant="body2">None</Typography>
                    )}
                  </Row>
                )}

                <Row gap={8}>
                  <Typography variant="body1" color={theme.colors.light.text.secondary}>
                    {oldPlayersByLeague['Prospect'].length} Prospect players
                  </Typography>
                  {!!oldPlayersByLeague['Prospect'].length && <LinkButton
                    onClick={() =>
                      setShowRemoved({ ...showRemoved, Prospect: !showRemoved.Prospect })
                    }
                  >
                    <Typography variant="body2">
                      {showRemoved.Prospect ? 'Hide' : 'Show'}
                    </Typography>
                  </LinkButton>}
                </Row>
                {showRemoved.Prospect && (
                  <Row gap={2} flexWrap>
                    {oldPlayersByLeague['Prospect']?.map((p) => renderDraggablePlayer(p))}
                    {!oldPlayersByLeague['Prospect'].length && (
                      <Typography variant="body2">None</Typography>
                    )}
                  </Row>
                )}
              </Container>
            )}
            {}
          </Container>
        )}

        {!!newPlayers?.length && (
          <Container>
            <Row gap={8}>
              <Typography variant="body1">
                ({newPlayers.length}) Acquired Players
              </Typography>
              <LinkButton onClick={() => setShowAdded(!showAdded)}>
                <Typography variant="body2">{showAdded ? 'Hide' : 'Show'}</Typography>
              </LinkButton>
            </Row>
            {showAdded && (
              <Container padding={2}>
                <Row gap={2} flexWrap>
                  {newPlayers?.map((p) => renderDraggablePlayer(p))}
                </Row>
              </Container>
            )}
          </Container>
        )}

        {!!newPlayers?.length && <Divider />}

        <BDDDepthChart
          depthChartId={scenario.depthChartId}
          editable={canEdit}
          showButtons={false}
          showProspects={false}
          showLists={false}
          showChanges={false}
          nonNHLDisplayMode={size === 'sm' ? "expandable" : 'visible'} // either visible, expandable or hidden
          playerSize={size}
          renderPlayer={renderPlayer}
          onUpdate={handleDCCacheUpdate}
          refetchWrapperQueries={refetchWrapperQueries}
        />
      </Column>
    </Container>
  );
};
