import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { create, useStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { SearchParams } from 'helpers/searchParams';
import { createJSONStorage, persist } from 'zustand/middleware';
import localforage from 'localforage';
import { buildQuery, sortAndFilterColumns } from './helpers';
import { getCurrentDraftYear } from 'helpers/hockeyutils';
import { getCurrentSeasonFilterValue } from '../helpers';
import { buildSetActions } from 'helpers/zustand';

const EPStatsContext = createContext();

const defaultFilters = {
  level: {
    id: 'level',
    type: 'select',
    cond: 'isin',
    value: ['professional', 'major-junior'],
  },
  season: {
    id: 'season',
    type: 'select',
    cond: 'isin',
    value: [getCurrentSeasonFilterValue()],
  },
  game_type: {
    id: 'game_type',
    type: 'select',
    cond: '=',
    value: 'REGULAR_SEASON',
  },
  position: {
    id: 'position',
    type: 'select',
    cond: '=',
    value: 'skaters',
  },
  draft_eligible_years: {
    id: 'draft_eligible_years',
    type: 'select',
    cond: 'includes',
    value: [getCurrentDraftYear()],
  },
};

const initialState = {
  filters: {},
  statFilters: [],
  groupBy: 'player,player_id,league,league_slug,season',
  sort: [{ id: 'stats_GP', desc: true }],
  filtersApplied: false,
  filterOptions: {},
  query: null,
  results: {},
  idToColumn: {},
  columns: [],
  showFilters: false,
  targetGroup: 'age',
  useZScore: false,
  indexColumnIds: [
    'player',
    'league',
    'team',
    'season',
    'age',
    'height',
    'weight',
    'position',
  ],
};

const createStore = (defaults, persistEnabled) => {
  let store = immer((set, get) => ({
    ...initialState,
    ...defaults,
    actions: {
      ...buildSetActions(set, initialState),
      setFilters: (filters) => {
        set((state) => {
          state.filters = { ...filters };
          state.filtersApplied = false;
        });
      },
      setStatFilters: (statFilters) => {
        set((state) => {
          state.statFilters = statFilters;
          state.filtersApplied = false;
        });
      },
      setGroupBy: (groupBy) => {
        set((state) => {
          state.groupBy = groupBy;
          state.filtersApplied = false;
        });
      },
      applyFilters: () => {
        set((state) => {
          state.query = buildQuery(state);
          state.filtersApplied = true;
        });
      },
      addColumns: (columns) => {
        set((state) => {
          const idToColumn = columns.reduce((idToColumn, column) => {
            idToColumn[column.id] = column;
            return idToColumn;
          }, {});

          const updatedIdToColumn = {
            ...state.idToColumn,
            ...idToColumn,
          };

          state.idToColumn = updatedIdToColumn;
          state.columns = sortAndFilterColumns(
            Object.values(updatedIdToColumn),
            get().indexColumnIds
          );
        });
      },
    },
  }));

  if (persistEnabled) {
    store = persist(store, {
      name: 'ep-stats-storage',
      storage: createJSONStorage(() => localforage),
      partialize: (state) => ({
        filterOptions: state.filterOptions,
        indexColumnIds: state.indexColumnIds,
      }),
    });
  }

  return create(store);
};

export const EPStatsContextProvider = ({ defaults = {}, persistEnabled, children }) => {
  const searchParams = new SearchParams();
  const savedFilters = searchParams.getSerialized('filters');
  const savedStatFilters = searchParams.getSerialized('statFilters');

  defaults = {
    ...defaults,
    filters: savedFilters || defaults.filters || defaultFilters,
    statFilters: savedStatFilters || [],
  };

  const store = useRef(createStore(defaults, persistEnabled)).current;

  return <EPStatsContext.Provider value={store}>{children}</EPStatsContext.Provider>;
};

export const useEPStatsContext = (selector) =>
  useStore(useContext(EPStatsContext), selector);

export const useEPStatsFilterValues = () =>
  useEPStatsContext((state) =>
    Object.fromEntries(Object.values(state.filters).map((f) => [f.id, f.value]))
  );
