import { useRef, useState } from 'react';
import { createContext } from 'react';
import { useBddVideoPlayer, useVideoClipPlayer } from '../hooks';
import loadingImage from 'assets/images/den_notext.png';

export const VideoPlayerContext = createContext();

export const VideoPlayerProvider = ({
  children,
  currentClip,
  playbackMode = 'offsets',
  autoPlay = true,
}) => {
  const videoRef = useRef(null);

  const {
    playerState,
    startTime,
    endTime,
    duration,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    handleVideoTrim,
    seekToSeconds,
    toggleMute,
    setFrontPad,
    setBackPad,
    isClipEdited,
    resetClip,
  } = useVideoClipPlayer({
    videoRef,
    clip: currentClip,
    autoPlay,
  });

  const video = (
    <video
      style={{ width: '100%', height: '100%' }}
      src={currentClip?.videoUrl}
      ref={videoRef}
      onTimeUpdate={handleOnTimeUpdate}
      poster={loadingImage}
      playsInline={true}
      type="video/mp4"
    />
  );

  const context = {
    playerState,
    duration,
    startTime,
    endTime,
    isClipEdited,
    resetClip,
    video,
    videoRef,
    handleVideoProgress,
    handleVideoSpeed,
    handleVideoTrim,
    seekToSeconds,
    toggleMute,
    togglePlay,
    setFrontPad,
    setBackPad,
    autoPlay,
  };

  return (
    <VideoPlayerContext.Provider value={context}>{children}</VideoPlayerContext.Provider>
  );
};
