import Icon from "components/bdd/Icon";
import { Column, Container, Row } from "components/bdd/Layout"
import { Typography } from "components/bdd/Typography"
import { TooltipSpan } from "components/reports";
import { theme } from "constants";
import { formatDollars } from "helpers/contracts";
import { numberToOrdinal } from "helpers/helpers";
import { ArrowClockwise, ArrowDown, ArrowUp, ExclamationTriangle, ListOl } from "react-bootstrap-icons";
import { HoverInteractive } from "components/bdd";
import { capitalizeFirstLetter } from "helpers/string";
import { useModal } from "components/bdd/Modal";
import { ScenarioCapBreakdown } from "./ScenarioCapBreakdown";
import { IconButton } from "components/bdd/Button";
import { roundToX } from "helpers/data";
import { ScenarioChecks } from "./ScenarioChecks";
import { getRedGreenColor, sortNumeric, sortNumericAccessor } from "helpers/tables";
import BDDSortableTable from "components/bdd/bddsortabletable";
import { useScenario } from "./hooks/useScenario";
import { useEffect } from "react";


const greenColor = theme.colors.states.success;
const redColor = theme.colors.states.danger;

export const TeamStrengthRankChange = ({ agentBased, baseAgentBased }) => {
  const agentRank = agentBased?.rank;
  const compAgentRank = baseAgentBased?.rank;
  const agentRankChange = compAgentRank - agentRank;
  return <Row 
    gap={4}
    style={{ color: agentRankChange > 0 ? greenColor : agentRankChange < 0 ? redColor : 'none' }}
  >
    <Typography variant='body2'>
      {numberToOrdinal(agentRank)} 
    </Typography>
    {agentRankChange > 0
      ? <Row>
          <Typography variant='caption'><Icon icon={<ArrowUp/>}/></Typography>
          <Typography variant='caption'>{agentRankChange} spot{Math.abs(agentRankChange) > 1 ? 's' : ''}</Typography> 
        </Row>
      : agentRankChange < 0
      ? <Row>
          <Typography variant='caption'><Icon icon={<ArrowDown/>}/></Typography>
          <Typography variant='caption'>{agentRankChange} spot{agentRankChange > 1 ? 's' : ''}</Typography> 
        </Row>
      : <Typography variant='caption'>(No change)</Typography>
    }
  </Row>
}


export const TeamStrengthField = ({ agentBased }) => {
  /* As opposed to TeamStrengthRankChange, this field doens't compare scenario to base depth chart, 
  it just looks at the scenario's DC relative to DC of 31 other teams based on recent games rosters
  */
 if (!agentBased || !agentBased.teamStrengths) return null;
 const rank = agentBased?.targetTeamStrength?.rank;
 const teamStrengths = agentBased?.teamStrengths;
 const columns = [
  {
    id: 'rank',
    Header: '',
    Cell: ({row}) => <Typography variant='body1'>
      {row.original.rank}.
    </Typography>
  },
  {
    id: 'teamSlug',
    Header: <Typography variant='body1'>Team</Typography>,
    Cell: ({row}) => (
      <Typography variant='body1'>
        {row.original.teamSlug.split('.')[1].toUpperCase()}
      </Typography>
    )
  },
  {
    id: 'winProb',
    Header: <Typography variant='body1'>Strength</Typography>,
    accessor: (d) => d.winProb,
    Cell: ({row}) => <Typography variant='body2' style={{ backgroundColor: getRedGreenColor(row.original.winProb, 0.4, 0.6) }}>
      {roundToX(row.original.winProb*100, 1)}%
    </Typography>,
    sortType: sortNumeric
  },
  {
    id: 'warFwd',
    Header: <Typography variant='body1'>Forwards</Typography>,
    accessor: (d) => d.warFwd,
    Cell: ({row}) => <Typography variant='body2' style={{ backgroundColor: getRedGreenColor(row.original.warFwd, 0, 3) }}>
      {roundToX(row.original.warFwd, 2)}
    </Typography>,
    sortType: sortNumeric,
  },
  {
    id: 'warDef',
    Header: <Typography variant='body1'>Defense</Typography>,
    accessor: (d) => d.warDef,
    Cell: ({row}) => <Typography variant='body2' style={{ backgroundColor: getRedGreenColor(row.original.warDef, 0, 3) }}>
      {roundToX(row.original.warDef, 2)}
    </Typography>,
    sortType: sortNumeric
  },
  {
    id: 'gsaa',
    Header: <Typography variant='body1'>Goaltending</Typography>,
    accessor: (d) => d.gsaa,
    Cell: ({row}) => <Typography variant='body2' style={{ backgroundColor: getRedGreenColor(row.original.gsaa, -20, 20) }}>
      {roundToX(row.original.gsaa, 2)}
    </Typography>,
    sortType: sortNumeric
  },
 ]
 return (
    <Row 
      gap={4}
      style={{  color: rank <= 8 ? greenColor : rank > 24 ? redColor : 'none' }}
    >
      <HoverInteractive
        content={(
          <Container maxHeight={400} overflow='auto' paddingRight={4}>
            <BDDSortableTable
              data={teamStrengths}
              columns={columns}
              defaultSort={[{ id: 'winProb', desc: true }]}
            />
          </Container>
        )}
      >
        <Typography variant='body2'>
          {numberToOrdinal(rank)} 
        </Typography>
      </HoverInteractive>  
    </Row>
  )
}


export const ScenarioDiagnostics = ({ scenario: scenarioArg, proObjective, externalLoading }) => {
  const { showModal, renderModal, setShowModal } = useModal({
    title: `${scenarioArg.title} Cap Breakdown`,
  })
  const { scenario, refetch, loading } = useScenario({
    id: scenarioArg.id,
    cacheOnly: false
  });

  useEffect(() => {
    if (!scenario) return;
    if (scenario.lastModified > scenario.cacheCapBreakdownTimestamp) {
      console.log('Cap breakdown is stale, refetching...')
      refetch();
    }
  }, [scenario]);
  
  if (!scenario) return (
    <Typography variant='body2'>Loading...</Typography>
  );

  const handleRefresh = () => {
    refetch({
      proObjectiveSlug: proObjective?.slug,
      includeCapSheet: true,
      includeChecks: true,
      includeTeamStrength: true,
      skipCache: true,
      scenarioIdFilter: [scenarioArg.id],
    })
    // refetchBaseAgentData({
    //   slug: proObjective?.slug,
    //   includeTeamStrength: true,
    //   skipCache: true
    // });
  }

  const agentBased = scenario?.teamStrength;

  const capSheet = scenario?.capSheet;
  const teamSummary = capSheet?.teamSummary;
  const capCeiling = capSheet.salaryCap?.upperLimit;
  const sortedCaps = [
    ...scenario.capSheet.players.map((p) => ({
      name: `${p?.bddPlayer?.firstname} ${p?.bddPlayer?.lastname}`,
      ...p,
    }))
  ].sort((a, b) => b.capHit - a.capHit);

  let fields = [
    {
      id: 'hit',
      label: <Row gap={4} justifyContent='space-between'>
        <Typography variant='body1'>Proj. Cap Hit</Typography>
        <IconButton icon={<ListOl/>} onClick={() => setShowModal(true)} />
      </Row>,
      tooltip: 'Projected cap hit',
      value: <Container>
        <HoverInteractive content={
          <Container maxHeight="300px" overflowY='auto'>
            <Column>
              {sortedCaps.map((p, i) => (
                <Container key={p.bddPlayer?.slug || i}>
                  <Row gap={24} justifyContent='space-between'>
                    <Typography variant='body2'>{p.name}</Typography>
                    <Typography variant='body1'>{formatDollars(p.capHit)} x{p.remainingTerm}</Typography>
                  </Row>
                </Container>
              ))}
            </Column>
          </Container>
        }>
          <span style={{ color: teamSummary.projectedCapHit > capCeiling ? theme.colors.states.danger : 'black' }}>
            {formatDollars(teamSummary.projectedCapHit)}
          </span>
          {' '}/ {formatDollars(capCeiling)}
        </HoverInteractive>        
      </Container>
    },
    {
      id: 'space',
      label: 'Cap Room',
      tooltip: 'Projected cap space under the upper limit',
      value: <span style={{ color: teamSummary.projectedCapSpace < 0 ? theme.colors.states.danger : 'black' }}>
        {formatDollars(teamSummary.projectedCapSpace)}
      </span>
    },
    ...(!!agentBased ? [
      {
        id: 'teamStrength',
        label: 'Team Strength Rank',
        tooltip: 'Team strength based on player WARs and roster slotting',
        value: <TeamStrengthField agentBased={agentBased} />
      }
    ] : [])
  ]
  if (!!scenario.depthChecks) {
    
  }

  return (
    <Container>
      {showModal && renderModal(<ScenarioCapBreakdown scenario={scenario} />)}
      {(loading || externalLoading) && <Typography variant='stat'>
        Updating values...
      </Typography>}
      <Row justifyContent='space-between'>
        <Row gap={32} rowGap={1} justifyContent='start' alignItems='start' flexWrap>
          {fields.map(({ id, label, tooltip, value }) => (
            <Container key={id}>
              <Typography variant='body1' color='#777'>
                <TooltipSpan content={tooltip}>
                  {label}
                </TooltipSpan>
              </Typography>
              <Typography variant='body2'>{value}</Typography>
            </Container>  
          ))}

          <ScenarioChecks scenario={scenario} />

        </Row>

        <IconButton 
          icon={<ArrowClockwise/>} 
          onClick={handleRefresh}
          loading={loading || externalLoading}
          tooltip="Refresh diagnostics"
          disabled={loading || externalLoading}
          disabledTooltip={"Refresh in progress"}
        />
      </Row>
      
      {teamSummary.projectedCapHit > capCeiling && <Typography variant='h5' color='red'>
        <Row gap={4}>
          <Icon icon={<ExclamationTriangle/>}/>
          <Typography>Cap Ceiling Exceeded</Typography>
        </Row>
      </Typography>}

      {teamSummary.rosterSize > 23 && <Typography variant='h5' color='red'>
        <Row gap={4}>
          <Icon icon={<ExclamationTriangle/>}/>
          <Typography>NHL Roster Size {'>'} 23</Typography>
        </Row>
      </Typography>}

      

    </Container>
  )
}