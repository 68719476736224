import { Column, Container, Row } from "components/bdd/Layout";
import { Typography } from "components/bdd/Typography";
import { theme } from "constants";
import { formatDollars } from "helpers/contracts";
import { positionToAbbrev } from "helpers/hockeyutils";
import { capitalizeFirstLetter } from "helpers/string";
import { useContext, useEffect, useMemo, useState } from "react";
import { useScenario } from "./hooks/useScenario";
import { useScenarioCapBreakdown } from "./hooks/useScenarioCapBreakdown";
import { TeamActiveRosterBrief, TeamActiveRosterBriefFields } from "components/TeamsV2/LineupAndCap/TeamActiveRosterBrief";
import { TeamPlayerCapHitTables } from "components/TeamsV2/LineupAndCap/TeamPlayerCapHitTable";
import { DELETE_DEPTH_CHART_SPOT_WRAPPER, UPDATE_BDD_DEPTH_CHART } from "apollo/queries/depthchart.queries";
import { useMutation } from "@apollo/client";
import { toastBddApiError, toastError } from "components/bdd/bddtoasts";
import { useCreateTransactionModal } from "../Transactions/useCreateTransactionModal";
import { useHandleAttachTx } from "./ScenarioTx";
import { ScenarioContext } from "./ScenarioContextProvider";
import { FixedIndicator } from "components/bdd/FixedIndicator/FixedIndicator";
import { Button, Spinner } from "react-bootstrap";
import Icon from "components/bdd/Icon";
import { PlusCircle } from "react-bootstrap-icons";
import { ModalContext, useModal } from "components/bdd/Modal";
import { CapHitAddModal, ScenarioContractBuyout } from "components/TeamsV2/LineupAndCap/CapHitAddModal";
import { DepthChartSpotWrapperForm } from "components/ProScouting/DepthChartSpotWrapperForm";
import { LinkButton } from "components/bdd/Button";
import { ScenarioNotes } from "./ScenarioNotes";
import { Divider } from "components/bdd/Divider";



export const ScenarioCapBreakdown = ({ 
  scenario, 
  showBrief=true, 
  showNotes=true,
  allowEdit=true,
  defaultEdit=false,
}) => {
  const [editMode, setEditMode] = useState(defaultEdit && scenario.canEdit);
  const { capBreakdown, placeholder, loading, refetch } = useScenarioCapBreakdown({ scenarioId: scenario.id });
  const bfPlayers = capBreakdown?.bfPlayers;
  const deadSpace = capBreakdown?.deadSpace;
  const depthChart = scenario.depthChart;
  const teamSummary = capBreakdown?.teamSummary;

  useEffect(() => {
    if (!scenario || !capBreakdown) return;
    if (scenario.lastModified > capBreakdown.lastModified) {
      console.log('Cap breakdown is stale, refetching...')
      refetch();
    }
  }, [scenario])

  const { renderModal: renderContextModal, hideModal: hideContextModal } = useContext(ModalContext);
  const { renderModal } = useCreateTransactionModal({});
  const attachTx = useHandleAttachTx({ 
    scenarioId: scenario.id,  
    onUpdate: () => {
      refetch();      
    }
  });

  // Status is determined by a combination of "overrides" in scenario payload AND player's depth chart position
  const { updateScenario, updateScenarioLoading } = useContext(ScenarioContext);

    const [updateBddDepthChart, { loading: dcLoading }] = useMutation(UPDATE_BDD_DEPTH_CHART, {
      onCompleted: () => refetch(),
      onError: (error) => toastBddApiError(error),
    }
  );
  const getPlayer = (bddSlug) => {
    const depthPlayer = depthChart.depthChartPlayers.find(p => p.playerSlug == bddSlug);
    if (!depthPlayer) {
      toastError('Player not found in depth chart');
      return;
    }
    return depthPlayer;
  }
  const handleSetStatus = (bddSlug, status) => {
    const depthPlayer = getPlayer(bddSlug);
    if (!depthPlayer) return;
    updateScenario({
      variables: {
        input: {
          id: scenario.id,
        },
        playerStatusesToSet: [{
          bddPlayerSlug: bddSlug,
          status
        }]
      },
      onCompleted: () => refetch(),
    })
  }
  const handleCallUp = (bddSlug) => {
    handleSetStatus(bddSlug, 'Nhl');
  }
  const handleSendDown = (bddSlug) => {
    handleSetStatus(bddSlug, 'Minor');
  }
  const handleIr = (bddSlug) => {
    handleSetStatus(bddSlug, 'InjuredReserve');
  }
  const handleLtir = (bddSlug) => {
    handleSetStatus(bddSlug, 'LongTermInjuredReserve');
  }
  const handleRemoveDeadSpace = (dsId) => {
    updateScenario({
      variables: {
        input: {
          id: scenario.id,
        },
        deadSpaceToRemove: dsId
      },
      onCompleted: () => refetch(),
    })
  }
  const handleRemove = (bddSlug) => {
    const depthPlayer = getPlayer(bddSlug);
    if (!depthPlayer) return;
    updateBddDepthChart({ variables: { 
      input: { id: depthChart.id },
      playersToRemove: [bddSlug]
    }});
  }

  const [deleteWrapper, { loading: wrapperLoading }] = useMutation(DELETE_DEPTH_CHART_SPOT_WRAPPER, {
    onCompleted: () => refetch(),
  });
  const handleRemoveWrapper = (placeholderPlayer) => {
    const wrapper = capBreakdown.wrappers.find((w) => w.id == placeholderPlayer.slug);
    if (!wrapper) return;
    deleteWrapper({ variables: { id: wrapper.id }});
  }
  // Signings/trades mean new scenario transactions
  const handleSign = (bddSlug) => {
    renderModal({
      defaultTab: 'signing',
      canSign: true,
      canTrade: false,
      defaultSigningBDDPlayerSlug: bddSlug,
      onCompleted: (tx) => {
        attachTx(tx);
      }
    })
  }
  const handleTrade = (bddSlug) => {
    const depthPlayer = getPlayer(bddSlug);
    if (!depthPlayer) return;
    const otherTeam = scenario.teamSlug === 'nhl.bos' ? 'nhl.ana' : 'nhl.bos'
    renderModal({
      defaultTab: 'trade',
      canSign: false,
      canTrade: true,
      tradeFormProps: {
        defaultTeams: [scenario.teamSlug, otherTeam],
        defaultAssets: [{
          assetType: 'PLAYER',
          assetId: bddSlug,
          bddPlayerSlug: bddSlug,
          bddPlayer: depthPlayer.bddPlayer,
          fromTeamSlug: scenario.teamSlug,
          toTeamSlug: otherTeam,
        }],
      },
      onCompleted: (tx) => {
        attachTx(tx);
      }
    })
  }

  const handleBuyout = (bfPlayer) => {
    if (!bfPlayer.activeAndFutureContracts?.length) return;
    renderContextModal({
      title: <Typography variant='h5'>Buyout {bfPlayer.firstName} {bfPlayer.lastName} Contract</Typography>,
      body: (
        <Container padding={2}>
          <ScenarioContractBuyout
            scenario={scenario}
            defaultContractId={bfPlayer.activeAndFutureContracts[0].id}
            onCompleted={() => {
              refetch();
              hideContextModal();
            }}
          />
        </Container>
      )
    })
  }

  const handleEditWrapper = (placeholderPlayer) => {
    const wrapper = capBreakdown.wrappers.find((w) => w.id == placeholderPlayer.slug);
    renderContextModal({
      title: <Typography variant='h5'>Edit Cap Placeholder</Typography>,
      body: (
        <Container padding={4}>      
          <DepthChartSpotWrapperForm
            depthChartId={scenario.depthChartId}
            wrapper={wrapper}
            hideDepthFields={true}
            onSubmit={(values) => {
              refetch();
              hideContextModal();
            }}
          />
        </Container>
      )
    })
  }
  
  const { showModal, renderModal: renderCapModal, setShowModal } = useModal({
    title: 'Add Cap Hit',
  });
  const addCapModal = showModal && renderCapModal(
    <CapHitAddModal 
      scenario={scenario} 
      capBreakdown={capBreakdown} 
      onCompleted={() => {
        refetch();
        setShowModal(false);
      }}
    />
  )
  
  const topRightContent = (
    <Container>
      <Row gap={8}>
        {editMode && <Button variant='outline-dark' size='sm' onClick={() => setShowModal(true)}>
          <Row gap={4}>
            <Icon icon={<PlusCircle/>}/>
            <Typography variant='body2'>Add Cap hit</Typography>
          </Row>
        </Button>}

        {(scenario.canEdit && allowEdit && !defaultEdit) && (
          <Button variant='outline-dark' size='sm' onClick={() => setEditMode(!editMode)}>
            <Typography variant='body2'>{editMode ? 'Done' : 'Edit'}</Typography>
          </Button>
        )}
      </Row>
    </Container>
  );

  const doingWork = loading || updateScenarioLoading || dcLoading || wrapperLoading;
  if (!capBreakdown) return placeholder;
  return (
    <Container paddingBottom={20}>
      {addCapModal}
      {!!showBrief && !!teamSummary
        ? (
          <Container>
            {doingWork && <Typography variant='stat'>
              Updating values...
            </Typography>}
            <TeamActiveRosterBriefFields teamSlug={scenario.teamSlug} teamData={teamSummary} />
          </Container>
        )
        : !showBrief
        ? null
        : <Typography variant='body1' state='warning'>Error loading team cap information</Typography>
      }

      {showNotes && (
        <Container paddingTop={2} paddingBottom={2}>
          <ScenarioNotes scenario={scenario} allowEdit={scenario.canEdit} single={true} />
        </Container>
      )}

      <TeamPlayerCapHitTables 
        bfPlayers={bfPlayers} 
        deadSpace={deadSpace} 
        includeActions={editMode}
        actionsDisabled={updateScenarioLoading || dcLoading}
        topRightContent={topRightContent}
        actionCallbacks={{
          handleCallUp,
          handleSendDown,
          handleIr,
          handleLtir,
          handleRemove,
          handleSign,
          handleTrade,
          handleBuyout,
          handleEditWrapper,
          handleRemoveWrapper,
          handleRemoveDeadSpace: handleRemoveDeadSpace
        }}
      />
      {(doingWork) && <FixedIndicator>
        <Row gap={4}>
          <Spinner animation='border' size='sm'/>
          <Typography variant='body1'>
            Updating...
          </Typography>
        </Row>
      </FixedIndicator>}
    </Container>

  )
}



const OldScenarioCapBreakdown = ({ scenario }) => {
  const capSheet = scenario?.capSheet;
  const byPosition = useMemo(() => {
    if (!scenario?.capSheet) return null;

    const sortedCaps = [
      ...scenario.capSheet.players.filter(p => !!p.capHit).map((p) => ({
        name: `${p?.bddPlayer?.firstname} ${p?.bddPlayer?.lastname}`,
        ...p,
      })).concat(scenario.capSheet.wrappers.map((w) => ({
        name: `${w.lineNum} ${capitalizeFirstLetter(w.position)}`,
        ...w,
        capHit: w.capHit*1e6,
        remainingTerm: w.term,
        isWrapper: true
      })))
    ].sort((a, b) => b.capHit - a.capHit);

    return sortedCaps.reduce((acc, curr) => {
      let existing = acc.find(a => a.position === curr.position);
      if (!existing) {
        existing = { position: curr.position, totalCapHit: 0, players: [] };
        acc.push(existing);
      }
      existing.totalCapHit += curr.capHit;
      existing.players.push(curr);
      return acc
    }, [])
  }, [scenario]);

  if (!capSheet) return null;

  const posRows = [['leftwing', 'center', 'rightwing'], ['leftdefence', 'rightdefence', 'goalie']];
  return (
    <Container>
      {posRows.map((row, i) => (
        <Container key={i} style={i === 0 ? { borderBottom: '1px solid #aaa' } : {}}>
          <Row gap={4} justifyContent='space-between'>
            {row.map(p => (
              <Column key={p}>
                <Typography variant='body1' textAlign='center'>{positionToAbbrev(p)}</Typography>
                {byPosition?.find(b => b.position === p)?.players.map((p, i) => (
                  <Row key={p.name} gap={8} justifyContent='space-between'>
                    <Typography variant='body1' color={p.isWrapper ? '#777' : 'black'}>{p.name}</Typography>
                    <Typography variant='body2'>{formatDollars(p.capHit)} x{p.remainingTerm}</Typography>
                  </Row>
                ))}
                <Row style={{ borderTop: '1px solid #ddd' }} gap={8} justifyContent='space-between'>
                  <Typography variant='body1'>Total</Typography>
                  <Typography variant='body2'>{formatDollars(byPosition?.find(b => b.position === p)?.totalCapHit)}</Typography>
                </Row>
              </Column>
            ))}
          </Row>
        </Container>
      ))}
    </Container>
  )
}